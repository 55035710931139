<template>
  <vue-date-picker :enable-time-picker="false" format="dd.MM.yyyy" :modelValue="modelValue" @update:modelValue="val => $emit('update:modelValue', val)"></vue-date-picker>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  emits: ["update:modelValue"],
  components: {VueDatePicker},
  props: {
    modelValue: {
      type: Date,
      required: true
    }
  }
}
</script>