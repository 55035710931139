import firebaseApp from "@/firebaseApp";
import {
    getFirestore,
    collection,
    addDoc,
    getDocs,
    getDoc,
    deleteDoc,
    doc,
    updateDoc,
    query,
    orderBy,
    limit,
    startAt,
    serverTimestamp,
    where,
    setDoc,
    // connectFirestoreEmulator
} from "firebase/firestore";

const firestore = getFirestore(firebaseApp);

// connectFirestoreEmulator(firestore, 'localhost', 4040);

import store from "@/store";
import {markRaw} from "vue";


export default {
    state: {
        pageSize: 10,

        lastSnapshot: null,
        isLastPage: false,

        initialized: false,
        fetching: false,
        filtersActive: false
    },
    mutations: {
        setLastSnapshot(state, payload) {
            state.lastSnapshot = payload;
        },
        setIsLastPage(state, payload) {
            state.isLastPage = payload;
        },


        uninitialize(state) {
            state.initialized = false;
        },
        initialize(state) {
            state.initialized = true;
        },

        setFetching(state, payload) {
            state.fetching = payload;
        },
        setFiltersActive(state, payload) {
            state.filtersActive = payload;
        }
    },
    getters: {
        getIsLastPage(state) {
            return state.isLastPage;
        },
        getInitialized(state) {
            return state.initialized;
        },
        getFetching(state) {
            return state.fetching;
        },
        getFiltersAreActive(state) {
            return state.filtersActive;
        }
    },

    addMeta(objs) {
        let noArray = false;

        if (!Array.isArray(objs)) {
            noArray = true;
            objs = [objs];
        }

        objs.forEach(obj => {
            obj.meta = markRaw({});
        });

        return noArray ?
            objs[0] :
            objs;
    },

    getSubjectDocRef(root) {
        if (!store.getters["user/getUser"]) return null;

        if (root) return firestore;
        return doc(firestore, "subjects", store.getters["user/getUser"].activeSubject);
    },

    create(collectionName, payload, root = false, id = null) {
        payload.createdAt = serverTimestamp();
        payload.updatedAt = serverTimestamp();

        if (id) {
            const docRef = doc(this.getSubjectDocRef(root), collectionName, id);
            return setDoc(docRef, payload);
        }

        return addDoc(collection(this.getSubjectDocRef(root), collectionName), payload);
    },
    async list(args) {
        //Default hodnoty musia byť definovane tu a nie v argumentoch,
        //pretože undefined, kt. pride zhora ich overridne
        const defaults = {
            pageSize: 10,
            //POZOR: kôli tomuto musí byť na každom fielde createdAt
            _orderBy: "createdAt",
            order: "desc",
            _lastSnapshot: null,
            queries: [],
            root: false
        }

        const finalArgs = {...defaults, ...args};

        // console.log(args)

        const {
            collectionName,
            pageSize,
            _orderBy,
            order,
            _lastSnapshot,
            queries,
            root,
            subCollectionName,
        } = finalArgs;


        //Pre logovacie ucely
        // console.log({
        //     pageSize,
        //     _orderBy,
        //     order,
        //     _lastSnapshot,
        //     queries,
        //     root
        // })
        //Stack trace
        // const error = new Error();
        // console.log(error.stack);

        let _collection = collection(this.getSubjectDocRef(root), collectionName);

        if (subCollectionName)
            _collection = collection(_collection, subCollectionName);

        const qArgs = [];

        if (_orderBy)
            qArgs.push(orderBy(_orderBy, order));

        //Ak je pageSize 0, tak sa neobmedzuje
        if (pageSize)
            qArgs.push(limit(pageSize + 1));

        if (_lastSnapshot)
            qArgs.push(startAt(_lastSnapshot));

        if (queries?.length)
            queries.forEach(query => qArgs.push(where(query.queryField, query.queryMark, query.queryValue)));

        const q = query(_collection, ...qArgs);
        // console.log({q});

        try {
            const querySnapshot = await getDocs(q);

            const data = [];
            let lastSnapshot = null;

            //Pre logovacie ucely
            // console.log({resultLength: querySnapshot.docs.length});

            //index explicitne definujeme, pretoze forEach v tomto kontexte nieje nativny foreach z array, ale z firebase - ten do callbacku neposle index
            let index = 0;
            querySnapshot.forEach((doc) => {
                if (pageSize && index === pageSize) {
                    lastSnapshot = doc;
                    return;
                }

                data.push({...doc.data(), id: doc.id});
                index++;
            });

            return [data, lastSnapshot];
        } catch (e) {
            //TODO: produkčné logovanie na server
            console.error(e);

            throw new Error("Error while fetching data from database!");
        }
    },
    async retrieve(collectionName, id, root = false) {
        const docRef = doc(this.getSubjectDocRef(root), collectionName, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists())
            return {...docSnap.data(), id};

        throw new Error(`Document ${id} in collection ${collectionName} does not exist!`);
    },
    async update(collectionName, id, payload, root = false) {
        const updatedDoc = doc(this.getSubjectDocRef(root), collectionName, id);

        payload.updatedAt = serverTimestamp();

        await updateDoc(updatedDoc, payload);
    },
    async set(collectionName, id, payload, root = false) {
        const updatedDoc = doc(this.getSubjectDocRef(root), collectionName, id);
        payload.updatedAt = serverTimestamp();

        await setDoc(updatedDoc, payload);
    },
    delete(collectionName, id, root = false) {
        return deleteDoc(doc(this.getSubjectDocRef(root), collectionName, id));
    },

    async sendMail({to, from, subject, text, attachments}) {
        const formattedAttachments = [];

        attachments.forEach(attachment => {
            formattedAttachments.push({
                filename: attachment.name,
                content: attachment.b64,
                encoding: 'base64'
            });
        });

        const payload = {
            to,
            from,
            message: {
                subject,
                text,
                attachments: formattedAttachments
            },
        }

        const mailRef = collection(firestore, "mail");

        await addDoc(mailRef, payload);
    },

    getUidInCollection(collectionName, root = false) {
        return doc(collection(this.getSubjectDocRef(root), collectionName)).id;
    }
}