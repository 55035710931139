<template>
  <multiselect
      ref="multiselect"

      :class="{inFlexBox}"
      :multiple="multiple"
      :modelValue="modelValue"
      :options="options || []"
      :placeholder="placeholder"
      :label="label"
      :track-by="trackBy"
      :taggable="taggable"
      :internal-search="!invoiceOption"
      :searchable="searchable"
      :loading="loading"
      :close-on-select="closeOnSelect"
      :disabled="disabled"

      select-label=""
      selected-label=""
      deselect-label=""
      tagPlaceholder=""

      @tag="addTag"
      @search-change="handleSearchChange"
      @remove="removeTag"
      @select="onSelect"
  >
    <template #noResult>
      <span v-if="invoiceOption && search.length < 3">
        Zadajte aspoň 3 znaky
      </span>

      <span v-else>
        Neboli nájdené žiadne výsledky
      </span>
    </template>

    <template v-if="invoiceOption" #option="props">
      <div class="d-flex justify-content-between align-items-center">
        <span class="font-weight-bold">{{ props.option.name }}</span>
        <span class="text-muted text-uppercase">IČO: {{ props.option.ico }}</span>
      </div>

      <div class="my-2">{{ props.option.city }}</div>
    </template>

    <template v-else-if="editableOptions" #option="props">
      <div class="d-flex justify-content-between">
        <div style="max-width: 100px">
          <div v-if="props.option.isTag">{{ props.option.label }}</div>
          <input v-else v-model="optionLabelsCopy[props.option.value]"
                 @blur="closeDropdownOnFocusLoss"
                 @click="$event.target.focus()"
                 class="borderless-input ignore-blur" type="text">
        </div>

        <!--Ak má option.isTag === true, jedná sa o pridávaný choice, existuje len na úrovni dropdownu, nie je ešte zapísaný nikde-->
        <div v-if="!props.option.isTag && optionLabelsCopy[props.option.value] !== props.option.verbose">
          <i class="bi bi-check-lg cursor-pointer text-success"
             @click="$emit('updateChoiceVerbose', {field, optionVerbose: optionLabelsCopy[props.option.value], optionValue: props.option.value})"></i>
          <i class="cursor-pointer bi bi-x-lg text-danger"
             @click="optionLabelsCopy[props.option.value] = props.option.verbose"></i>
        </div>
        <div v-else-if="!props.option.isTag">
          <i class="bi bi-trash3-fill interactable-icon ii-gray" @click="$emit('deleteChoice', props.option.value)"></i>
        </div>
      </div>
    </template>

    <template #noOptions>
      <span v-if="invoiceOption && search.length < 3">
        Zadajte aspoň 3 znaky
      </span>

      <span v-else>
        {{ noOptions }}
      </span>
    </template>
  </multiselect>
</template>

<script>
export default {
  emits: [
    "update:modelValue",
    "addTag",
    "searchChange",
    "triggerLoading",
    "deleteChoice",
    "updateChoiceVerbose"
  ],
  name: "Dropdown",
  props: {
    modelValue: [String, Object],
    placeholder: {
      type: String,
      default: "Vyberte"
    },
    options: {
      type: Array,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    //Vyžaduje mať taggable: true, close-on-select: false a @delete-choice listener
    editableOptions: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: "verbose"
    },
    trackBy: {
      type: String,
      default: "value"
    },

    noOptions: {
      type: String,
      default: "Neboli nájdené žiadne výsledky"
    },

    inFlexBox: {
      type: Boolean,
      default: false
    },
    invoiceOption: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    //Kôli taggable, alebo editableOptions, passuje sa do add-tag event payloadu a používa sa na update choice pri danom fielde
    field: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      isOpen: true,

      search: "",
      currentSearchTimeout: false,

      //V tvare {value: label}. Používame na editovanie labelov existujúcich options
      optionLabelsCopy: {},
    }
  },
  mounted() {
    //Toto tu je kvôli tomu, že multiselect má v sebe input, ktorý sa zatvorí, ak stratí focus.
    //My chceme, aby sa zatvoril len ak stratí focus na niečo iné ako na iný option, kde môže upravovať choices
    if (this.editableOptions)
      this.$refs.multiselect.$refs.search.addEventListener("blur", e => {
        if (e.relatedTarget?.classList.contains("ignore-blur"))
          this.$refs.multiselect.isOpen = true;
      });

    // if (this.invoiceOption) {
    //   const spinnerWrapper = document.createElement("multiselect__spinner")[0];
    //
    //   spinnerWrapper.innerHTML = `
    //   <div class="spinner-border spinner-border-sm text-primary" role="status">
    //     <span class="sr-only">Loading...</span>
    //   </div>
    // `;
    // }
  },
  methods: {
    //@blur listener na borderless input v editableOptions
    closeDropdownOnFocusLoss(e) {
      //Toto súvisí s logikou v mounted(). Prakticky ak pôjdeme z borderless-input(ak editujeme option) na niečo iné,
      //ako ďalší takýto input, alebo hlavný search, dropdown sa zatvorí
      if (!e.relatedTarget?.classList.contains("ignore-blur") && !e.relatedTarget?.classList.contains("multiselect__option"))
        this.$refs.multiselect.isOpen = false;
    },

    onSelect(val) {
      if (this.editableOptions)
        return;

      this.$emit('update:modelValue', this.multiple ? [...this.modelValue, val] : val);
    },

    removeTag(el) {
      if (!this.taggable)
        return;

      const valueClone = JSON.parse(JSON.stringify(this.modelValue));
      const removedEl = valueClone.find(o => o.value === el.value);

      valueClone.splice(valueClone.indexOf(removedEl), 1);

      this.$emit("update:modelValue", valueClone);
    },

    handleSearchChange(e) {
      this.search = e;

      if (e.length >= 3)
        this.$emit('triggerLoading', true);
      else this.$emit('triggerLoading', false);

      if (this.currentSearchTimeout)
        clearTimeout(this.currentSearchTimeout);

      this.currentSearchTimeout = setTimeout(() => {
        this.$emit('searchChange', e);
      }, 1000);
    },

    addTag(e) {
      const option = {
        //camelCase label
        value: e.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+(\w)/g, g => g[1].toUpperCase()),
        verbose: e,
      }

      //Keď sa zmenia všetky choices, z nejakého dôvodu nefirne watcher. Zatiaľ workaround
      this.optionLabelsCopy[option.value] = option.verbose;

      this.$emit("update:modelValue", option);
      this.$emit('addTag', {field: this.field, option});
    }
  },
  watch: {
    options: {
      handler() {
        this.optionLabelsCopy = {};

        this.options.forEach(o => {
          this.optionLabelsCopy[o.value] = o.verbose;
        });
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss">
@import "../../css/global";

.multiselect.form-control {
  padding: 0 !important;
}

.multiselect__tags {
  @extend .form-control;

  height: 100% !important;
  min-height: 38px;
  border-radius: 0.25rem !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 8px;
  padding-top: 8px;

  & .multiselect__placeholder {
    color: #727272;
    font-size: 1rem;
    margin-left: 5px !important;
    margin-bottom: 8px !important;
    padding: 0 !important;
  }

  & .multiselect__tag {
    background: $primary !important;
    border-radius: 50px !important;
  }
}

.multiselect__option--highlight {
  background: $light !important;
  color: $primary !important;
}

.multiselect__input, .multiselect__single {
  //background: $lightest !important;
}

.inFlexBox {
  width: auto !important;
  flex-grow: 1 !important;
  height: fit-content !important;

  & .multiselect__tags {
    border-radius: 0 5px 5px 0 !important;
  }
}

.multiselect__spinner::before, .multiselect__spinner::after {
  border-color: $primary transparent transparent !important;
}

.multiselect--disabled .multiselect__select {
  background: none !important;
}
</style>