<template>
  <div class="row bg-light">
    <div class="col p-3 d-flex justify-content-between">
      <div>
        <span class="text-primary">ZFA</span> {{ new Date().getFullYear() }}<span
          class="text-primary">{{ invoice.variableSymbol?.toString().split("2023")[1] }}</span>
      </div>

      <div class="d-flex gap-large text-right">
        <div>
          <div class="font-weight-normal">Spolu bez DPH</div>
          <span>- {{ invoice.totalWithoutTax }} EUR</span>
        </div>

        <div>
          <div class="font-weight-normal">Spolu s DPH</div>
          <span>- {{ invoice.totalWithTax }} EUR</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepositInvoiceDeductionItem",
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>