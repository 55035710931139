import db from "../helpers/crud.js";

export default {
    namespaced: true,
    state() {
        return {
            ...db.state,

            invoices: [],
            invoice: null,
        }
    },
    mutations: {
        ...db.mutations,

        setInvoices(state, invoices) {
            state.invoices = invoices;
        },
        addInvoice(state, invoice) {
            state.invoices.push(invoice);
        },
        setInvoice(state, invoice) {
            state.invoice = invoice;
        },
        deleteInvoice(state, id) {
            if (state.invoice?.id === id)
                state.invoice = null;

            if (state.invoices.length) {
                const index = state.invoices.indexOf(state.invoices.find(invoice => invoice.id === id));

                if (index === -1)
                    throw new Error(`Invoice with ${id} doesn't exist.`);

                state.invoices.splice(index, 1);
            }
        },
        updateInvoice(state, {id, payload}) {
            if (!id) {
                id = state.invoice.id;
                Object.assign(state.invoice, payload);
            }

            if (state.invoices.length === 0) return;

            const invoice = state.invoices.find(c => c.id === id);

            if (!invoice) throw new Error(`Invoice with ${id} doesn't exist.`);

            Object.assign(state.invoices[state.invoices.indexOf(invoice)], payload);
        },
    },
    actions: {
        async updateNextVariableSymbol({dispatch, rootGetters}) {
            const currentData = rootGetters["subjectData/getInvoiceData"];

            const nextVariableSymbol = currentData.Meta.nextVariableSymbol + 1;
            await dispatch("subjectData/updateInvoiceData", {
                Meta: {
                    ...currentData.Meta,
                    nextVariableSymbol
                }
            }, {root: true});
        },

        async fetchInvoices({commit, state, rootGetters}, {orderBy, order, queries = []} = {
            _orderBy: "createdAt",
            order: "desc"
        }) {
            commit("setFetching", true);
            // commit("setFiltersActive", Boolean(queries && queries.length));

            if (rootGetters["user/getRoleInCurrentSubject"] === 'client')
                queries.push({
                    queryField: 'userAccess',
                    queryMark: 'array-contains',
                    queryValue: rootGetters["user/getUser"].uid
                });

            const [invoices, lastSnapshot] = await db.list({
                collectionName: "invoices",
                pageSize: 20,
                orderBy,
                order,
                _lastSnapshot: state.lastSnapshot,
                queries
            });

            commit("setInvoices", invoices);
            commit("setLastSnapshot", lastSnapshot);
            commit("setIsLastPage", Boolean(!lastSnapshot));
            commit("setFetching", false);

            if (!state.initialized)
                commit("initialize");
        },
        async fetchInvoice(context, id) {
            const invoice = await db.retrieve("invoices", id);
            context.commit("setInvoice", invoice);
            context.commit("addInvoice", invoice);

            return invoice;
        },
        async createInvoice({commit, dispatch, rootGetters}, payload) {
            const currentVariableSymbol = payload.variableSymbol;

            if (payload.isInvoiceGroup)
                delete payload.variableSymbol;

            const id = (await db.create("invoices", payload)).id;
            commit("addInvoice", {id, ...payload});

            await dispatch("updateNextVariableSymbol");
        },
        async updateInvoice({commit, getters}, {id, payload}) {
            const currentInvoiceData = getters.getInvoiceById(id);

            commit("updateInvoice", {id, payload});

            try {
                await db.update("invoices", id, payload);
            } catch (e) {
                commit("updateInvoice", currentInvoiceData);
                throw e;
            }
        },
        async deleteInvoice({commit}, id) {
            commit("deleteInvoice", id);

            await db.delete("invoices", id);
        },

        async createInvoiceGroup({commit, dispatch, rootGetters}, {rootInvoiceId, newInvoiceData}) {
            const rootInvoiceData = await db.retrieve("invoices", rootInvoiceId);

            rootInvoiceData.id = db.getUidInCollection("invoices");
            newInvoiceData.id = db.getUidInCollection("invoices");

            const depositInvoices = [rootInvoiceData];
            let finalInvoice = null;
            let status = "OPEN";

            if (newInvoiceData.isDepositInvoice) depositInvoices.push(newInvoiceData);
            else if (newInvoiceData.isFinal) {
                finalInvoice = newInvoiceData;
                status = "CLOSED";
            } else throw new Error("Invalid invoice type");

            const payload = {
                isInvoiceGroup: true,
                status,
                depositInvoices,
                finalInvoice,

                //Potrebujeme koli zmene nextVariableSymbol v createInvoice actione. Do vysledneho payloadu nejde
                variableSymbol: newInvoiceData.variableSymbol
            }

            const promises = [];

            promises.push(dispatch("createInvoice", payload));
            promises.push(dispatch("deleteInvoice", rootInvoiceId));

            await Promise.all(promises);
        },
        async createNewDepositInvoice({commit, dispatch, getters}, {invoiceGroupId, newInvoiceData}) {
            //Koli optimalizacii, ak uz mame invoiceGroupData pre konkretne id, tak ho nepotrebujeme znova fetchovat
            let invoiceGroupData = getters.getInvoiceById(invoiceGroupId);
            if (!invoiceGroupData) invoiceGroupData = await dispatch("fetchInvoice", invoiceGroupId);

            const depositInvoices = invoiceGroupData.depositInvoices;
            depositInvoices.push(newInvoiceData);

            const promises = [];

            promises.push(dispatch("updateInvoice", {
                id: invoiceGroupId,
                payload: {
                    depositInvoices
                }
            }));

            promises.push(dispatch("updateNextVariableSymbol"));

            await Promise.all(promises);
        },
        async createFinalInvoice({commit, dispatch, getters}, {invoiceGroupId, newInvoiceData}) {
            await dispatch("updateInvoice", {id: invoiceGroupId, payload: {finalInvoice: newInvoiceData}});
        },
        async deleteDepositInvoiceFromInvoiceGroup({commit, dispatch, getters}, {invoiceId, invoiceGroupId}) {
            //invoiceGroupData budu vždy v store pri volani tejto akcie
            let invoiceGroupData = getters.getInvoiceById(invoiceGroupId);
            const depositInvoices = invoiceGroupData.depositInvoices;

            depositInvoices.splice(depositInvoices.indexOf(depositInvoices.find(invoice => invoice.id === invoiceId)), 1);

            if (depositInvoices.length === 0) {
                await dispatch("deleteInvoice", invoiceGroupId);
                return;
            }

            await dispatch("updateInvoice", {id: invoiceGroupId, payload: {depositInvoices}});
        },
    },
    getters: {
        ...db.getters,

        getInvoices(state) {
            return state.invoices;
        },
        getInvoice(state) {
            return state.invoice;
        },
        getInvoiceById: (state) => (id) => state.invoices.find(invoice => invoice.id === id),
    }
}