import db from "../helpers/crud";
import cloud from "../helpers/storage";

import documentTemplateHandler from "@/mixins/documentTemplateHandler";

export default {
    namespaced: true,
    state() {
        return {
            ...db.state,

            documents: [],
            document: null,

            //Jedná sa o objekt, ktorý má:
            // -- kľúč id dokumentu
            // -- hodnotou pole objektov, ktoré reprezentujú dáta, ktoré sa majú použiť pri vytváraní dokumentu
            bulkProcessingData: {
                //"dokumentId1": [{firstName: Daniel, lastName: Klimek}, ...] - dáta prvého typu dokumentu
                //"dokumentId2": [{invoiceNumber: 20230001, total: 2894}, ...] - dáta druhého typu dokumentu
            },
            bulkProcessingDocumentIdsQueue: [],
            bulkProcessingCurrentDocumentIndex: null,
        }
    },
    mutations: {
        ...db.mutations,

        setDocuments(state, payload) {
            state.documents = payload;
        },
        addDocuments(state, payload) {
            state.documents.push(...payload);
        },
        appendDocuments(state, payload) {
            state.documents.push(payload);
        },

        updateDocument(state, {id, payload}) {
            if (id) {
                const document = state.documents.find(d => d.id === id);

                if (!document)
                    throw new Error(`Document with id ${id} doesn't exist.`);

                const index = state.documents.indexOf(document);

                Object.assign(state.documents[index], payload);
            } else
                Object.assign(state.document, payload);
        },
        setDocument(state, payload) {
            state.document = payload;
        },
        setDocumentById(state, id) {
            const doc = state.documents.find(d => d.id === id);

            if (!doc)
                throw new Error(`Document with id ${id} doesn't exist.`);

            state.document = doc;
        },
        removeDocument(state, id) {
            const document = state.documents.find(d => d.id === id);

            if (document)
                state.documents.splice(state.documents.indexOf(document), 1);
            else throw new Error(`Document with id ${id} doesn't exist.`);
        },

        setBulkProcessingData(state, payload) {
            state.bulkProcessingData = payload;
        },
        setBulkProcessingDocumentIdsQueue(state, payload) {
            state.bulkProcessingDocumentIdsQueue = payload;
        },
        addBulkProcessingDocumentIdsQueue(state, payload) {
            state.bulkProcessingDocumentIdsQueue.push(payload);
        },
        setBulkProcessingCurrentDocumentIndex(state, payload) {
            state.bulkProcessingCurrentDocumentIndex = payload;
        },
    },
    actions: {
        async initializeBulkProcessing({commit, getters}, {modelData, documentData}) {
            //modelData(štruktura priamo z db) - môže byť napr. pole klientov, z kt. sa majú vytvoriť dokumenty
            //documentData(štruktura priamo z db) - dáta samotných dokumentov, z kt. dokážeme vytiahnúť template dokumentu

            commit("setBulkProcessingDocumentIdsQueue", []);

            //Stiahneme dokumenty
            const filesAsArrayBuffer = await Promise.all(documentData.map(d => {
                    if (!getters.getBulkProcessingDocumentIdsQueue.includes(d.id))
                        commit("addBulkProcessingDocumentIdsQueue", d.id);

                    return cloud.download(d.location)
                        .then(url => fetch(url))
                        .then(async res => {
                            if (!res.ok)
                                throw new Error("Failed to fetch the file");

                            return {arrayBuffer: await res.arrayBuffer(), id: d.id, name: d.name};
                        })
                }
            ));

            //Vytvoríme docxtemplater pre každý typ dokumentu a ku inštanciam si uložíme aj id a názov dokumentu
            const docxtemplaters = await Promise.all(filesAsArrayBuffer.map(file =>
                documentTemplateHandler.methods.initDocxtemplater(file.arrayBuffer)
                    .then(instance => ({instance, meta: {docId: file.id, docName: file.name}}))
            ));

            //Inicializujeme tags
            const allTags = await Promise.all(docxtemplaters.map(docxtemplater => {
                return {
                    ...documentTemplateHandler.methods.initTags(docxtemplater.instance.moduleInstances.inspectModule),
                    meta: docxtemplater.meta
                }
            }));

            //Zbuildime data pre každý typ dokumentu na základe modelData
            const bulkProcessingData = allTags.reduce((acc, tags) => {
                acc[tags.meta.docId] = modelData.map(data => {
                    tags.meta.modelId = data.id;

                    //TODO: toto bude iné pre každý model, zatiaľ pre klientov nechať napevno
                    tags.meta.modelRepresentation = `${data.firstName}__${data.lastName}`;
                    if (data.birthNumber)
                        tags.meta.modelRepresentation += `__${data.birthNumber}`;

                    return documentTemplateHandler.methods.buildDocData(tags, data);
                });

                //Treba setnúť aj binárne dáta dokumentov
                acc[`${tags.meta.docId}ArrayBuffer`] = filesAsArrayBuffer.find(f => f.id === tags.meta.docId).arrayBuffer;
                //A samotný dokument z db
                acc[`${tags.meta.docId}Document`] = documentData.find(d => d.id === tags.meta.docId);

                return acc;
            }, {});

            commit("setBulkProcessingCurrentDocumentIndex", 0);
            commit("setBulkProcessingData", bulkProcessingData);
        },

        async fetchDocument(context, id) {
            const document = await db.retrieve("documents", id);
            document.id = id;

            if (!document)
                throw new Error(`Document with id ${id} doesn't exist.`);

            context.commit("setDocument", document);
            context.commit("initialize");
        },

        async fetchDocuments({commit, state}, {orderBy, order, nextPage} = {nextPage: true}) {
            commit("setFetching", true);

            if (!nextPage)
                commit("setLastSnapshot", null);

            const [documents, lastSnapshot] = await db.list({
                collectionName: "documents",
                pageSize: state.pageSize,
                _lastSnapshot: state.lastSnapshot,
                orderBy,
                order,
            });

            commit("setLastSnapshot", lastSnapshot);
            commit("setIsLastPage", Boolean(!lastSnapshot));

            commit("addDocuments", db.addMeta(documents));
            commit("setFetching", false);
        },

        async uploadDocument({commit, rootGetters}, {name, file}) {
            let extension = null;

            switch (file.type) {
                case "application/pdf":
                    extension = "pdf";
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    extension = "docx";
                    break;
                default:
                    throw new Error("Invalid file type.");
            }

            const location = `${rootGetters["cloud/getRootLocation"]}/documents/${name}.${extension}`;

            const uploadResult = await cloud.upload(location, file);
            const finalLocation = uploadResult.ref.fullPath;

            const payload = {
                location: finalLocation,
                name: finalLocation.split("/").pop(),
                type: file.type
            }

            const id = (await db.create("documents", payload)).id;

            payload.createdAt = {toDate: () => new Date()};

            commit("appendDocuments", {
                id,
                ...payload
            });
        },

        async deleteDocument(context, id) {
            const document = JSON.parse(JSON.stringify(context.getters.getDocumentById(id)));
            context.commit("removeDocument", id);

            await Promise.all([db.delete("documents", id), cloud.delete(document.location)]);
        },

        async updateDocument(context, {id, payload}) {
            let before = context.getters.getDocument;

            if (id)
                before = context.getters.getDocumentById(id);

            context.commit("updateDocument", {id, payload});

            if (!id)
                id = before.id;

            try {
                await db.update("documents", id, payload);
            } catch (e) {
                context.commit("updateDocument", {id, payload: before});
                return Promise.reject(e)
            }
        },
    },
    getters: {
        ...db.getters,

        getDocuments(state) {
            return state.documents
        },
        getDocumentById: (state) => (id) => state.documents.find(d => d.id === id),
        getDocument(state) {
            return state.document;
        },

        getBulkProcessingData(state) {
            return state.bulkProcessingData;
        },
        getBulkProcessingDocumentIdsQueue(state) {
            return state.bulkProcessingDocumentIdsQueue;
        },
        getBulkProcessingCurrentDocumentIndex(state) {
            return state.bulkProcessingCurrentDocumentIndex;
        }
    }
}