<template>
  <overlay name="Nová faktúra">
    <template #content>
      <div class="text-left max-width-md invoice-wrapper" :class="{deposit: newInvoice.isDepositInvoice}">
        <div class="row">
          <h2 class="font-weight-bold col">{{ newInvoice.isDepositInvoice ? 'Z' : '' }}FA 2023<span
              class="text-primary">{{ invoiceData.Meta.nextVariableSymbol.toString().split("2023")[1] }}</span></h2>
        </div>

        <hr>

        <div class="mb-5">
          <div class="row">
            <h4 class="col">Základné údaje</h4>
          </div>

          <div :class="{'bg-light': autocompleteCompanyDetail}" class="row transition-fast py-3">
            <div class="col-6 dropdown-label">
              <div>
                {{ invoicingAsBuyer ? 'Dodávateľ' : 'Odberateľ' }}

                <finstat-autocomplete-dropdown :disabled="Boolean(newInvoice.clientId)" placeholder="Odberateľ"
                                               @change="autocompleteCompanyDetail = $event"></finstat-autocomplete-dropdown>
              </div>
            </div>

            <div class="col-6 small text-muted" v-if="autocompleteCompanyDetail">
              <company-detail :autocomplete-company-detail="autocompleteCompanyDetail"></company-detail>
            </div>
          </div>

          <div class="form-group my-3">
            <div class="text-primary cursor-pointer user-select-none d-flex" style="gap: 5px;"
                 @click="supplierCollapsed = !supplierCollapsed">
              <div class="row">
                <div class="col" style="line-height: 24px">{{ invoicingAsBuyer ? 'Odberateľ' : 'Dodávateľ' }}</div>
              </div>

              <div>
                <i class="bi bi-chevron-down align-self-center" v-if="!supplierCollapsed"></i>
                <i v-else class="bi bi-chevron-right align-self-center"></i>
              </div>
            </div>

            <collapse class="v-collapse" :when="supplierCollapsed">
              <div class="py-3 bg-light row">
                <div class="col">
                  <company-detail class="col-6 p-0"
                                  :autocomplete-company-detail="{
                    ...invoiceData,

                     bankAccount: {
                      ...newInvoice.seller.bankAccount
                    }
                  }"></company-detail>
                </div>

                <div v-if="!invoicingAsBuyer" class="col-6 form-group d-flex flex-column justify-content-center">
                  <label>
                    IBAN
                    <input placeholder="IBAN" class="form-control mb-2" type="text"
                           v-model="newInvoice.seller.bankAccount.IBAN">
                  </label>

                  <label>
                    SWIFT
                    <input placeholder="SWIFT" class="form-control" type="text"
                           v-model="newInvoice.seller.bankAccount.SWIFT">
                  </label>
                </div>
              </div>
            </collapse>
          </div>

          <div class="form-group row">
            <label class="col-4">Dátum vystavenia
              <date-picker v-model="newInvoice.issueDate"></date-picker>
            </label>

            <label class="col-4">Dátum dodania
              <date-picker v-model="newInvoice.deliveryDate"></date-picker>
            </label>

            <label class="col-2">Splatnosť
              <input type="number" placeholder="Splatnosť" class="form-control" v-model="newInvoice.dueInDays">
            </label>
          </div>

          <div class="form-group row mb-5">
            <label class="col-4">Variabilný symbol
              <input type="text" class="form-control" v-model="newInvoice.variableSymbol"
                     placeholder="Variabilný symbol">
            </label>
          </div>
        </div>

        <hr>

        <div class="mb-5">
          <div class="row">
            <h4 class="col">Ďalšie údaje</h4>
          </div>

          <div class="form-group row">
            <label class="col-4">Mena
              <input disabled type="text" class="form-control" v-model="newInvoice.currency" placeholder="Mena">
            </label>

            <div class="col-4 dropdown-label">Spôsob úhrady
              <dropdown v-model="newInvoice.wayOfPayment" placeholder="Spôsob úhrady" :options="wayOfPaymentChoices"
                        label="label" track-by="value"></dropdown>
            </div>
          </div>

          <div class="form-group row">
            <div class="col d-flex gap-default">
              <toggle v-model="newInvoice.isDepositInvoice"></toggle>

              <label class="form-check-label" for="flexSwitchCheckDefault">Zálohová faktúra
              </label>
            </div>
          </div>

        </div>

        <hr>

        <div class="mb-5">
          <div class="d-flex justify-content-between">
            <div class="row">
              <h4 class="col align-self-center">Položky</h4>
            </div>

            <div class="align-items-center d-flex gap-large">
              <div>
                <div class="text-muted invoice-inner-item-width">Množstvo</div>
              </div>
              <div>
                <div class="text-muted invoice-inner-item-width">MJ</div>
              </div>
              <div>
                <div class="text-muted invoice-inner-item-width">Cena bez DPH</div>
              </div>
              <div>
                <div class="text-muted invoice-inner-item-width">DPH %</div>
              </div>
              <div>
                <div class="text-muted invoice-inner-item-width">Spolu</div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <invoice-inner-item
                v-for="(invoiceItem, index) in newInvoice.invoiceItems"
                :key="`invoice-item-${index}`"
                :invoice-item="invoiceItem"
                :index="index"
                :currency="newInvoice.currency"
                @change="newInvoice.invoiceItems[index][$event.key] = $event.value"
                :deletable="newInvoice.invoiceItems.length > 1"
                @remove="newInvoice.invoiceItems.splice($event, 1)"
            ></invoice-inner-item>
          </div>

          <div class="text-right my-3">
            <div class="text-primary cursor-pointer user-select-none" @click="addNewInvoiceItem">+ nová položka</div>
          </div>
        </div>

        <hr>

        <div v-if="newInvoice.isFinal" class="mb-5">
          <div class="row">
            <h4 class="col align-self-center">Odpočet záloh</h4>
          </div>

          <div class="deposit" v-if="invoice.isInvoiceGroup">
            <deposit-invoice-deduction-item v-for="(depositInvoice, index) in invoice.depositInvoices"
                                            :invoice="depositInvoice"
                                            :key="`depo-${index}`"></deposit-invoice-deduction-item>
          </div>

          <div class="deposit font-weight-bold" v-else>
            <deposit-invoice-deduction-item :invoice="invoice"></deposit-invoice-deduction-item>
          </div>
        </div>

        <div class="mb-5">
          <div class="row justify-content-end">
            <div class="col-2 text-right">
              Spolu bez DPH:
            </div>

            <div class="col-1 font-weight-bold">
              {{ totals.totalWithoutTax }} EUR
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-2 text-right">
              DPH:
            </div>

            <div class="col-1 font-weight-bold">
              {{ totals.totalTaxRate || 0 }} %
            </div>
          </div>
          <div class="row justify-content-end text-primary">
            <div class="col-2 text-right">
              Spolu s DPH:
            </div>

            <div class="col-1 font-weight-bold">
              {{ totals.totalWithTax }} EUR
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button :disabled="!totals.totalWithoutTax" type="submit" class="btn btn-primary" @click="createInvoiceMethod">
        Vytvoriť novú faktúru
        <span v-if="creatingInvoice" class="spinner-border-sm spinner-border"></span>
      </button>
    </template>
  </overlay>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import choices from "@/mixins/choices";

import DatePicker from "@/components/ui_components/DatePicker.vue";
import Dropdown from "@/components/ui_components/Dropdown.vue";
import Overlay from "@/containers/app/Overlay.vue";
import InvoiceInnerItem from "@/components/invoices/InvoiceInnerItem.vue";
import FinstatAutocompleteDropdown from "@/components/invoices/FinstatAutocompleteDropdown.vue";
import CompanyDetail from "@/components/invoices/CompanyDetail.vue";
import DepositInvoiceDeductionItem from "@/components/invoices/DepositInvoiceDeductionItem.vue";

export default {
  name: "NewInvoiceOverlay",
  components: {
    DepositInvoiceDeductionItem,
    FinstatAutocompleteDropdown,
    InvoiceInnerItem,
    Dropdown,
    Overlay,
    DatePicker,
    CompanyDetail
  },
  mixins: [choices],
  data() {
    return {
      client: null,

      supplierCollapsed: false,

      dateFormat: "dd.MM.yyyy",

      creatingInvoice: false,

      autocompleteCompanyDetail: null,

      newInvoiceInGroup: false,
      relatedInvoiceId: null,
      invoiceGroupId: null,
      depositSums: {
        withTax: 0,
        withoutTax: 0,
      },

      //ak organizácia vystavuje FA za klienta, tak invoicingAsBuyer === true && newInvoice.clientId !== null(nadobúda ID klienta - dodávateľa)
      invoicingAsBuyer: false,

      newInvoice: {
        userAccess: [],
        clientId: null,

        variableSymbol: "",

        issueDate: new Date(),
        deliveryDate: new Date(),
        dueInDays: 14,

        seller: {
          name: "",
          address: "",
          ico: "",
          dic: "",
          payingVat: false,
          legalForm: "",

          bankAccount: {
            IBAN: "",
            SWIFT: "",
          }
        },

        buyer: {
          name: "",
          address: "",
          ico: "",
          dic: "",
          payingVat: false,
          legalForm: ""
        },

        invoiceItems: [
          {
            name: "",
            description: "",
            unit: "",
            quantity: null,
            unitPrice: null,
            taxRate: null,

            total: 0,
            totalWithTax: 0,
          }
        ],

        wayOfPayment: {label: 'Bankový prevod', value: 'TRANSFER'}, // TRANSFER, CASH, CASH_ON_DELIVERY, CARD,
        status: "DRAFT",  // DRAFT, SENT, PAID, CANCELED

        currency: "EUR",
        totalWithoutTax: 0,
        totalTaxRate: 0,
        totalWithTax: 0,

        notes: "",

        isDepositInvoice: false,
        isFinal: false,
        isInvoiceGroup: false,
      },
    }
  },
  async created() {
    //POZOR: this.invoice môže byť buď invoiceGroup alebo invoice, záleží od toho, či sa vytvára aj skupina faktúr - invoice je zalohova FA, z kt. sa vytvara
    //alebo len faktúra v skupine - invoice je skupina faktúr
    if (this.$route.query.newInvoiceInGroup && this.$route.query.final === 'true') {
      let id = this.$route.query.invoiceGroupId;

      if (!id)
        id = this.$route.query.relatedInvoiceId;

      await this.fetchInvoice(id);
    }

    if (this.$route.query.forClient) {
      this.invoicingAsBuyer = true;
      this.newInvoice.clientId = this.$route.query.forClient;

      this.client = this.getClientById(this.newInvoice.clientId);

      this.newInvoice.userAccess = this.client.userAccess;

      if (!this.client)
        this.client = await this.fetchClient(this.newInvoice.clientId)
    }

    this.initializeInvoiceData();
  },
  methods: {
    ...mapActions({
      fetchInvoice: "invoices/fetchInvoice",
      createInvoice: "invoices/createInvoice",

      createInvoiceGroup: "invoices/createInvoiceGroup",
      createNewDepositInvoice: "invoices/createNewDepositInvoice",
      createFinalInvoice: "invoices/createFinalInvoice",

      fetchClient: "clients/fetchClient",
    }),

    initializeInvoiceData() {
      if (this.invoicingAsBuyer && this.client)
        this.autocompleteCompanyDetail = this.client.finstatResponse;

      this.newInvoice.variableSymbol = this.invoiceData.Meta.nextVariableSymbol;

      const myOrganizationsInvoiceData = {
        name: this.invoiceData.Name,
        address: `${this.invoiceData.Street} ${this.invoiceData.StreetNumber}, ${this.invoiceData.City} ${this.invoiceData.ZipCode}`,
        ico: this.invoiceData.Ico,
        dic: this.invoiceData.Dic || "",
        payingVat: !!this.invoiceData.IcDPH,
        legalForm: this.invoiceData.LegalFormText,

        bankAccount: {
          IBAN: this.invoiceData.IBAN,
          SWIFT: this.invoiceData.SWIFT,
        }
      };

      if (!this.invoicingAsBuyer)
        this.newInvoice.seller = myOrganizationsInvoiceData;
      else {
        delete myOrganizationsInvoiceData.bankAccount;
        this.newInvoice.buyer = myOrganizationsInvoiceData;
      }

      if (!this.$route.query.newInvoiceInGroup) return;
      //Inicializacia faktury v pripade, že vytvarame/upravujeme skupinu faktur
      const {invoiceGroupId, relatedInvoiceId, final} = this.$route.query;

      this.newInvoiceInGroup = true;
      this.invoiceGroupId = invoiceGroupId;
      this.relatedInvoiceId = relatedInvoiceId;

      this.newInvoice.isDepositInvoice = final === 'false';
      this.newInvoice.isFinal = final === 'true';

      if (this.newInvoice.isFinal) {
        //V prípade finálnej invoice chceme getnúť sumu záloh na odpočítanie
        if (!this.invoice.isInvoiceGroup) {
          this.depositSums.withTax = this.invoice.totalWithTax;
          this.depositSums.withoutTax = this.invoice.totalWithoutTax;
        } else {
          this.depositSums.withTax = this.invoice.depositInvoices.reduce((acc, invoice) => acc + invoice.totalWithTax, 0);
          this.depositSums.withoutTax = this.invoice.depositInvoices.reduce((acc, invoice) => acc + invoice.totalWithoutTax, 0);
        }
      }
      //------------------------
    },

    async createInvoiceMethod() {
      this.creatingInvoice = true;

      this.newInvoice.totalWithoutTax = this.totals.totalWithoutTax;
      this.newInvoice.totalWithTax = this.totals.totalWithTax;
      this.newInvoice.totalTaxRate = this.totals.totalTaxRate;

      if (this.newInvoiceInGroup) {
        if (this.newInvoice.isFinal) {
          this.newInvoice.totalWithoutTax -= this.depositSums.withoutTax;
          this.newInvoice.totalWithTax -= this.depositSums.withTax;
        }

        if (!this.invoiceGroupId)
          await this.createInvoiceGroup({
            rootInvoiceId: this.relatedInvoiceId,
            newInvoiceData: this.newInvoice,
          });
        else {
          if (this.newInvoice.isFinal)
            await this.createFinalInvoice({invoiceGroupId: this.invoiceGroupId, newInvoiceData: this.newInvoice});
          else if (this.newInvoice.isDepositInvoice)
            await this.createNewDepositInvoice({
              invoiceGroupId: this.invoiceGroupId,
              newInvoiceData: this.newInvoice
            });
          else throw new Error("Invoice is not final nor deposit");
        }
      } else
        await this.createInvoice(this.newInvoice);

      this.creatingInvoice = false;

      await this.$router.push({name: "Invoices"});
    },

    addNewInvoiceItem() {
      this.newInvoice.invoiceItems.push({
        name: "",
        description: "",
        unit: "",
        quantity: null,
        unitPrice: null,
        taxRate: null,
        total: 0
      });
    },
  },
  computed: {
    ...mapGetters({
      invoiceData: "subjectData/getInvoiceData",
      invoice: "invoices/getInvoice",

      getClientById: "clients/getClientById",
    }),

    totals() {
      let totalWithoutTax = this.newInvoice.invoiceItems.reduce((acc, item) => acc + item.total, 0);
      let totalWithTax = this.newInvoice.invoiceItems.reduce((acc, item) => acc + item.totalWithTax, 0);

      if (this.newInvoice.isFinal) {
        totalWithoutTax -= this.depositSums.withoutTax;
        totalWithTax -= this.depositSums.withTax;
      }

      const totalTaxRate = ((totalWithTax - totalWithoutTax) / totalWithoutTax * 100);

      return {
        totalWithoutTax,
        totalWithTax,
        totalTaxRate,
      }
    },
  },
  watch: {
    autocompleteCompanyDetail(to) {
      if (this.invoicingAsBuyer) {
        this.newInvoice.seller = {
          name: to.Name,
          address: `${to.Street} ${to.StreetNumber}, ${to.City} ${to.ZipCode}`,
          ico: to.Ico,
          dic: to.Dic || "",
          payingVat: !!to.IcDPH,
          legalForm: to.LegalFormText
        }

        if (this.client)
          this.newInvoice.seller.bankAccount = this.client.bankAccount;
      } else
        this.newInvoice.buyer = {
          name: to.Name,
          address: `${to.Street} ${to.StreetNumber}, ${to.City} ${to.ZipCode}`,
          ico: to.Ico,
          dic: to.Dic || "",
          payingVat: !!to.IcDPH,
          legalForm: to.LegalFormText
        }
    },

    "newInvoice.invoiceItems": {
      handler(to) {
        this.newInvoice.invoiceItems.forEach(item => {
          item.total = item.quantity * item.unitPrice;
          item.totalWithTax = item.total * (1 + item.taxRate / 100);
        });
      },
      deep: true
    },
  }
}
</script>

<style scoped lang="scss">
.deposit {
  --toggle-bg-on: #009d68 !important; //$primary
  --toggle-border-on: #009d68 !important; //$primary
}

.invoice-wrapper {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bi {
  font-size: 10px;
}

.row {
  margin-left: -20px;
  margin-right: -20px;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-left: -20 px;
  padding-right: -20 px;
}
</style>

<style lang="scss">
.deposit {
  & .text-primary {
    color: #009d68 !important;
  }
}
</style>