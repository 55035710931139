<template>
  <div :class="{'bg-light': index % 2 === 0}" class="p-3 invoice-inner-item-wrapper mx-20px-minus position-relative">
    <div class="d-flex justify-content-between mb-3">
      <div class="d-flex flex-column">
        <input placeholder="Názov" @change="emitChange('name', $event.target.value)" :value="invoiceItem.name" class="borderless-input font-weight-bold mb-3">
      </div>

      <div class="d-flex flex-column">
        <div class="d-flex gap-large">
          <input type="number" placeholder="Množstvo" @change="emitChange('quantity', $event.target.value)" :value="invoiceItem.quantity" class="borderless-input invoice-inner-item-width">
          <input placeholder="Merná jednotka" @change="emitChange('unit', $event.target.value)" :value="invoiceItem.unit" class="borderless-input invoice-inner-item-width">
          <input type="number" placeholder="Cena bez DPH" @change="emitChange('unitPrice', $event.target.value)" :value="invoiceItem.unitPrice" class="borderless-input invoice-inner-item-width">
          <input type="number" placeholder="DPH %" @change="emitChange('taxRate', $event.target.value)" :value="invoiceItem.taxRate" class="borderless-input invoice-inner-item-width">
          <div class="invoice-inner-item-width font-weight-bold">{{ invoiceItem.quantity * invoiceItem.unitPrice }} {{currency}}</div>
        </div>
      </div>
    </div>

    <input placeholder="Popis" @change="emitChange('description', $event.target.value)" :value="invoiceItem.description" class="borderless-input w-100">

    <div @click="deleteItem" class="trash" v-if="deletable">
      <i class="bi bi-trash3-fill interactable-icon ii-gray"></i>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['change', 'remove'],
  name: "InvoiceInnerItem",
  props: {
    invoiceItem: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      default: "EUR"
    },
    deletable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitChange(key, value) {
      this.$emit("change", {key, value, index: this.index});
    },
    deleteItem() {
      this.$emit("remove", this.index);
    }
  }
}
</script>

<style lang="scss">
.invoice-inner-item-width {
  width: 100px;
}

.trash {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(50%, -50%);

  width: 30px;
  height: 30px;
}
</style>