import '@babel/polyfill';
import 'mutationobserver-shim';
import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';

import router from './router';
import store from './store';

import FloatingVue from 'floating-vue'
import Multiselect from 'vue-multiselect';
import Toast, {useToast} from 'vue-toastification';
import {Collapse} from 'vue-collapsed';
import Toggle from '@vueform/toggle'
import {ContentLoader} from 'vue-content-loader'
import {vMaska} from "maska"

import '@popperjs/core/dist/umd/popper.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "vue-multiselect/dist/vue-multiselect.css";
import "@vueform/toggle/themes/default.css";
import 'floating-vue/dist/style.css'
import 'vue-toastification/dist/index.css';

import '@/css/global.scss';

const TOAST_CONFIG = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
}

const app = createApp(App)
app
    .directive('maska', vMaska)

    .use(store)
    .use(router)
    .use(FloatingVue)
    .use(Toast, TOAST_CONFIG)

    .component('multiselect', Multiselect)
    .component("collapse", Collapse)
    .component("toggle", Toggle)
    .component("content-loader", ContentLoader)

    .mount('#app');

app.config.globalProperties.$toast = useToast();