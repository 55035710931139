<template>
    <component v-if="currentOverlay" :is="currentOverlay" v-bind="currentOverlayProps"></component>
</template>

<script>
import {mapGetters} from "vuex";
import NewDocumentOverlay from "@/containers/app/overlays/NewDocumentOverlay";
import NewInvoiceOverlay from "@/containers/app/overlays/NewInvoiceOverlay.vue";

export default {
  name: "OverlayViewer",
  components: {
    NewDocumentOverlay,
    NewInvoiceOverlay,
  },
  computed: {
    ...mapGetters({
      currentOverlay: "overlays/getCurrentOverlay",
      currentOverlayProps: "overlays/getCurrentOverlayProps",
      visible: "overlays/getVisible"
    }),
  },
}
</script>

<style scoped>

</style>