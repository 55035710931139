<template>
  <label :class="{white}" class="file-uploader position-relative cursor-pointer m-0">
    <span v-if="fileName" id="close-wrapper" class="close" @click="removeFile">
      <i id="close" class="bi bi-x-circle-fill"></i>
    </span>

    <span class="absolute-center text-center">
      <i class="bi" :class="icon" style="font-size: 35px"></i>
      {{ fileName || name }}
    </span>
    <input ref="fileInput" @change="handleChange" type="file" class="d-none">
  </label>
</template>

<script>
export default {
  emits: ["upload"],
  name: "UploadFile",
  props: {
    name: String,
    icon: {
      type: String,
      default: "bi-cloud-upload-fill"
    },
    white: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileName: null
    }
  },
  methods: {
    handleChange(e) {
      this.fileName = e.target.files[0].name;
      this.$emit('upload', e);
    },

    removeFile(e) {
      e.preventDefault();
      this.fileName = null;
      this.$refs.fileInput.value = null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/global";

.white {
  border-color: white!important;
  color: white!important;
}

.file-uploader {
  border: dashed 1px $primary;
  border-radius: 10px;
  width: 150px;
  height: 150px;
  color: $primary
}

.close {
  position: absolute;
  right: 0;
  color: $primary!important;
  transform: translate(50%, -50%);
  font-size: 20px;
  opacity: 1!important;
}


</style>