import db from "../helpers/crud";
import cloudFunctionsController from "@/mixins/api_controllers/cloudFunctionsController";

export default {
    namespaced: true,

    state() {
        return {
            ...db.state,

            clients: [],
            client: null,
            isLastPage: false,

            signature: null,

            clientFields: {
                email: {
                    type: "email",
                    verbose: "email"
                },
                firstName: {
                    type: "text",
                    verbose: "meno"
                },
                lastName: {
                    type: "text",
                    verbose: "priezvisko"
                },
                maidenName: {
                    type: "text",
                    verbose: "rodné priezvisko"
                },
                birthDate: {
                    type: "date",
                    verbose: "dátum narodenia"
                },
                sex: {
                    type: "text",
                    verbose: "pohlavie",
                },
                birthNumber: {
                    type: "text",
                    verbose: "rodné číslo"
                },
                healthInsuranceProvider: {
                    type: "text",
                    verbose: "zdravotná poisťovňa",
                    taggable: true,
                },
                documentNumber: {
                    type: "text",
                    verbose: "číslo dokladu"
                },
                typeOfDocument: {
                    type: "text",
                    verbose: "typ dokladu",
                    taggable: true,
                },
                birthPlace: {
                    type: "text",
                    verbose: "miesto narodenia"
                },
                familyState: {
                    type: "text",
                    verbose: "rodinný stav",
                    taggable: true,
                },

                nationality: {
                    type: "text",
                    verbose: "štátna príslušnosť",
                    taggable: true,
                },
                dateOfIssue: {
                    type: "date",
                    verbose: "dátum vydania dokladu"
                },
                dateOfExpiry: {
                    type: "date",
                    verbose: "dátum expirácie dokladu"
                },

                addressStreet: {
                    type: "text",
                    verbose: "adresa - ulica"
                },
                addressNumber: {
                    type: "text",
                    verbose: "adresa - číslo"
                },
                addressCity: {
                    type: "text",
                    verbose: "adresa - mesto"
                },
                addressZip: {
                    type: "text",
                    verbose: "adresa - PSČ"
                },
                addressCountry: {
                    type: "text",
                    verbose: "adresa - krajina"
                },

                //additional fields
                branchOffice: {
                    type: "text",
                    verbose: "pobočka",
                    taggable: true,
                },
                position: {
                    type: "text",
                    verbose: "pozícia",
                    taggable: true,
                },
                wage: {
                    type: "number",
                    verbose: "plat",
                },
                hours: {
                    type: "number",
                    verbose: "hodiny",
                },
                dateOfEmployment: {
                    type: "date",
                    verbose: "dátum nástupu",
                }
            },

            clientSubjectInfoFields: {
                Ico: "IČO",
                Dic: "DIČ",
                IcDPH: "IČ DPH",
                Name: "názov subjektu",
            },

            bankAccountFields: {
                IBAN: "IBAN",
                SWIFT: "SWIFT",
            },

            choices: {
                status: {
                    missing_docs: "Chýbajúce dokumenty",
                    finished: "Vporiadku",
                }
            },

            importMaps: [],
        }
    },
    mutations: {
        ...db.mutations,

        setClients(state, payload) {
            state.clients = payload;
        },
        addClients(state, payload) {
            state.clients.push(...payload);
        },
        pushClient(state, payload) {
            //Aby bol novopridaný klient na prvom mieste
            state.clients = [payload].concat(state.clients);
        },
        setClient(state, payload) {
            state.client = payload;
        },
        deleteClient(state, id) {
            if (state.client?.id === id)
                state.client = null;

            if (state.clients.length) {
                const index = state.clients.indexOf(state.clients.find(client => client.id === id));

                if (index === -1)
                    throw new Error(`Client with ${id} doesn't exist.`);

                state.clients.splice(index, 1);
            }
        },
        updateClient(state, {id, payload}) {
            if (!id) {
                id = state.client.id;
                Object.assign(state.client, payload);
            }

            if (state.clients.length === 0) return;

            const client = state.clients.find(c => c.id === id);

            if (!client) throw new Error(`Client with ${id} doesn't exist.`);

            Object.assign(state.clients[state.clients.indexOf(client)], payload);
        },

        setSignature(state, payload) {
            state.signature = payload;
        },
    },
    actions: {

        async fetchClient({commit}, id) {
            const client = await db.retrieve("clients", id);
            commit("setClient", client);

            return client;
        },
        async fetchClients({commit, state, getters}, {orderBy, order, queries, nextPage} = {nextPage: true}) {
            commit("setFetching", true);
            commit("setFiltersActive", Boolean(queries && queries.length));

            //TODO: Zapracovať do abstract/base.js
            if (!nextPage)
                commit("setLastSnapshot", null);

            const [clients, lastSnapshot] = await db.list({
                collectionName: "clients",
                pageSize: state.pageSize,
                _orderBy: orderBy,
                order,
                _lastSnapshot: state.lastSnapshot,
                queries
            });

            commit("setLastSnapshot", lastSnapshot);
            commit("setIsLastPage", Boolean(!lastSnapshot));

            commit("addClients", db.addMeta(clients));
            commit("setFetching", false);

            if (!state.initialized)
                commit("initialize");
        },
        async createClient({state, commit, getters, rootGetters}, obj) {
            const payload = {};

            const additionalFields = rootGetters["schema/getFieldsForModel"]("client") || {};
            const allFields = {...state.clientFields, ...additionalFields};

            Object.keys(allFields).forEach(key => {
                if (obj[key]) {
                    payload[key] = obj[key];

                    if (allFields[key].type === "date")
                        payload[key] = new Date(obj[key]);
                }
            });

            //Treba explicitne pridať, pretože signauture sa nenachadza v clientFields
            if (obj.signature)
                payload.signature = obj.signature;

            //Treba špecifikovať, aby sme mohli podľa týchto fieldov filtrovať - ['lastName']
            if (payload.lastName)
                payload.lastNameSearch = payload.lastName
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");

            payload.status = "missing_docs";

            try {
                payload.id = (await db.create("clients", payload)).id;

                if (getters.getClients?.length) //Ak je velkosť 0 nepridávať, pretože sa aj tak inicializuju klienti na novo(kôli importu klientov)
                    commit("pushClient", db.addMeta(payload));
                else commit("uninitialize");
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async deleteClient(context, id) {
            context.commit("deleteClient", id);

            await db.delete("clients", id);
        },
        async updateClient(context, {id, payload}) {
            let client = context.getters.getClient;

            if (id)
                client = context.getters.getClientById(id);

            context.commit("updateClient", {id, payload});

            try {
                await db.update("clients", client.id, payload);
            } catch (e) {
                context.commit("setClient", client);
                context.commit("updateClient", {id, payload: client});

                return Promise.reject(e);
            }
        },

        async inviteClient({rootGetters, getters, commit}, id = null) {
            let client = null;

            if (id)
                client = getters.getClientById(id);
            else client = getters.getClient;

            //Nakoľko sa jedná o mixin, kt. sa v tomto prípade nepoužíva v komponente triggerujeme initAxios() explicitne
            //Vytváram falošný kontext, kt. má dáta potrebné pre použitie metód z cloudFunctionsController.js
            const cfEnv = {
                axiosCF: cloudFunctionsController.methods.initAxios(true),
            }

            const token = await cloudFunctionsController.methods.inviteUser.call(
                cfEnv,
                client.email,
                rootGetters["user/getUser"].activeSubject
            );

            //Prenášame na register page, potrebne data do finishInvitation cloud function
            const encodedData = btoa(JSON.stringify({
                firstName: client.firstName,
                lastName: client.lastName,
                email: client.email,
                clientId: client.id,
                role: "client"
            }));

            await db.sendMail({
                to: client.email,
                from: rootGetters["user/getUser"].smtpEmail,
                subject: "Pozvánka do aplikácie iDocs",
                text: `
                    Dobrý deň ${client.firstName},
                    \n
                    boli ste pozvaný do aplikácie iDocs. Pre prihlásenie kliknite na nasledujúci odkaz: https://data-gather-605a4.web.app/auth/register/?token=${token}&data=${encodedData}
                    Odkaz je platný 48 hodín od prijatia tohto emailu. V prípade, že odkaz nebudete môcť využiť, kontaktujte nás na emailovej adrese: ${rootGetters["user/getUser"].smtpEmail}
                    \n
                    S pozdravom,
                    ${rootGetters["user/getUser"].firstName}
                    \n\n
                    Tento email bol vygenerovaný automaticky. Prosím neodpovedajte naň.
                `,
                attachments: [],
            });

            await db.update("clients", client.id, {invitationSent: true});
            commit("updateClient", {payload: {invitationSent: true}});

            this.$toast("Pozvánka bola úspešne odoslaná.");
        }
    },
    getters: {
        ...db.getters,

        getClients(state) {
            return state.clients;
        },
        getClientById: (state) => (id) => {
            if (id === state.client?.id)
                return state.client;

            return state.clients.find(c => c.id === id)
        },
        getClientFields(state, getters, rootState, rootGetters) {
            return state.clientFields;
        },
        getClient(state) {
            return state.client;
        },
        getSignature(state) {
            return state.signature;
        },
        getChoices(state) {
            return state.choices;
        },
        getStatusChoices(state) {
            return Object.keys(state.choices.status).map(k => ({value: k, label: state.choices.status[k]}));
        },
        getClientSubjectInfoFields(state) {
            return state.clientSubjectInfoFields;
        },
        getBankAccountFields(state) {
            return state.bankAccountFields;
        }
    }
}