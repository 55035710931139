<template>
  <dropdown @search-change="autocompleteFilter = $event"
            invoice-option
            v-model="autocompleteCompany"
            :placeholder="placeholder"
            :options="autocompleteOptions"
            :loading="autocompleteFetching"
            @trigger-loading="autocompleteFetching = $event"
            label="name"
            track-by="ico"
            :disabled="disabled"
  >
  </dropdown>
</template>

<script>
import Dropdown from "@/components/ui_components/Dropdown.vue";
import functionsController from "@/mixins/api_controllers/cloudFunctionsController";

export default {
  emits: ['change'],
  name: "FinstatAutocompleteDropdown",
  components: {Dropdown},
  mixins: [functionsController],
  props: {
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      autocompleteFilter: "",
      autocompleteOptions: [],
      autocompleteCompany: null,

      autocompleteFetching: false,
      autocompleteCompanyDetailFetching: false,
    }
  },
  watch: {
    async autocompleteFilter(to) {
      if (to.length < 3) {
        this.autocompleteOptions = [];
        return
      }

      try {
        this.autocompleteFetching = true;

        const result = await this.autocomplete(to);

        if (result.ApiAutocomplete.Results === "") {
          this.autocompleteOptions = [];
          return;
        }

        this.autocompleteOptions = result.ApiAutocomplete.Results.Company.map(val => {
          return {name: val.Name, ico: val.Ico, city: val.City}
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.autocompleteFetching = false;
      }
    },
    async autocompleteCompany(to) {
      this.autocompleteCompanyDetailFetching = true;

      try {
        const response = await this.companyDetail(to.ico);

        this.autocompleteCompanyDetail = response.DetailResult;

        this.$emit('change', this.autocompleteCompanyDetail);
      } catch (e) {
        console.error(e);
      } finally {
        this.autocompleteCompanyDetailFetching = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>