<template>
  <overlay small name="Nahrajte dokument">
    <template #content>
      <div class="form-row text-left gap-default">
        <div>
          <upload-file @upload="document.file = $event.target.files[0]" name="Dokument"
                       icon-classes="bi bi-file-earmark-fill">
          </upload-file>
        </div>
        <div>
          <label for="input-name">Názov dokumentu</label>
          <input v-model="document.name" type="text" class="form-control" id="input-name"
                 placeholder="Názov dokumentu">
        </div>
      </div>
    </template>

    <template #footer>
      <button type="submit" class="btn btn-primary" @click="uploadDocumentMethod"
              :disabled="!(document.file && document.name)">
        Vytvoriť nový dokument
        <span v-if="uploadingDocument" class="spinner-border-sm spinner-border"></span>
      </button>
    </template>
  </overlay>
</template>

<script>
import Overlay from "@/containers/app/Overlay.vue";
import UploadFile from "@/components/ui_components/UploadFile.vue";

import {mapActions, mapMutations} from "vuex";
import functionsController from "@/mixins/api_controllers/cloudFunctionsController";

export default {
  name: "NewDocumentModal",
  components: {
    Overlay,
    UploadFile
  },
  mixins: [functionsController],
  data() {
    return {
      uploadingDocument: false,
      document: {
        name: null,
        file: null,
      },
    }
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter")
        this.uploadDocumentMethod();
    });
  },
  methods: {
    ...mapMutations({
      closeOverlay: "overlays/closeOverlay"
    }),
    ...mapActions({
      uploadDocument: "documents/uploadDocument",
    }),
    async uploadDocumentMethod() {
      if(!(this.document.file && this.document.name)) return;

      this.uploadingDocument = true;

      //TODO: čo spravíme s pdf ?
      // if (this.document.file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      //   try {
      //     const b64 = await new Promise((resolve, reject) => {
      //       const reader = new FileReader();
      //       reader.readAsDataURL(this.document.file);
      //       reader.onload = () => resolve(reader.result.split(",")[1]);
      //       reader.onerror = (error) => reject(error);
      //     });
      //
      //     const config = {
      //       headers: {
      //         'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      //       }
      //     }
      //
      //     this.document.file = await this.convertToPdf(b64, this.document.file.name, config);
      //   } catch (e) {
      //     console.error(e);
      //     alert("Pri konverzii dokumentu došlo k chybe. Skúste to prosím neskôr.");
      //
      //     canUpload = false;
      //   }
      // }

      try {
          await this.uploadDocument(this.document);
      } catch (e) {
        console.error(e);
        this.$toast.error(`Pri uploade dokument došlo k chybe. ${e}`);
      }

      this.document = {
        name: null,
        file: null,
      };

      this.uploadingDocument = false;
      this.closeOverlay();
    },
  }
}
</script>

<style lang="scss" scoped>
</style>