export default {
    methods: {
        async fetchB64(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return await this.blobToB64(blob);
        },

        async blobToB64(blob) {
            return await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    let base64data = reader.result;
                    const prefix = 'base64,';
                    let base64index = base64data.indexOf(prefix) + prefix.length;
                    base64data = base64data.substring(base64index);
                    resolve(base64data);
                }
            });
        },

        async b64ToFile(data, filename, mimeType) {
            const response = await fetch(`data:${mimeType};base64,${data}`);
            const blob = await response.blob();
            return new File([blob], filename, { type: mimeType });
        },

        fileToUint8Array(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const arrayBuffer = event.target.result;
                    const uint8Array = new Uint8Array(arrayBuffer);
                    resolve(uint8Array);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsArrayBuffer(file);
            });
        }

    }
}