import db from "../helpers/crud";

export default {
    namespaced: true,
    state() {
        return {
            invoiceData: null,
            initialized: false,
        }
    },
    mutations: {
        setInvoiceData(state, payload) {
            state.invoiceData = payload;
        },

        initialize(state) {
            state.initialized = true;
        }
    },
    actions: {
        async initialize({commit}) {
            const promises = [];

            try {
                const invoiceData = await db.retrieve("subjectData", "invoiceData");

                if(!invoiceData.Meta) {
                    invoiceData.Meta = {
                        nextVariableSymbol: new Date().getFullYear() + "000001"
                    };

                    promises.push(db.update("subjectData", "invoiceData", {Meta: invoiceData.Meta}));
                }

                commit("setInvoiceData", invoiceData);
            } catch (e) {
                console.error(e);
            } finally {
                commit("initialize");

                if(promises.length)
                    await Promise.all(promises);
            }
        },

        async updateInvoiceData({commit, getters}, payload) {
            let currentData = getters["getInvoiceData"];

            if(!currentData)
                currentData = {}

            payload = Object.assign(currentData, payload);

            try {
                commit("setInvoiceData", payload);

                await db.set("subjectData", "invoiceData", payload);
            } catch (e) {
                commit("setInvoiceData", currentData);
                console.error(e);
            }
        },
    },
    getters: {
        getInvoiceData(state) {
            return state.invoiceData;
        },

        getInitialized(state) {
            return state.initialized;
        }
    }
}