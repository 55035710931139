<template>
  <!--  <div class="overlay-bg"></div>-->

  <div ref="overlay" id="overlay" class="overlay shadow-default">
    <div ref="header" class="d-flex justify-content-between header">
      <div v-if="overlayHistory.length" class="cta text-secondary" @click="goBack"><i
          class="bi bi-arrow-left-short"></i> späť na {{ overlayHistory[overlayHistory.length - 1].label }}
      </div>

      <div class="text-secondary">
        <slot name="header">{{ name }}</slot>
      </div>

      <div @click="closeMethod">
        <i class="bi bi-x-lg"></i>
      </div>
    </div>
    <div ref="contentWrapper" class="h-100 content overflow-auto max-width-lg" style="overflow-x: hidden!important;">
      <slot name="content"></slot>
    </div>
    <div ref="footer" class="text-left footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";

export default {
  name: "Overlay",
  props: {
    name: String,
    small: {
      type: Boolean,
      default: false
    },
    customGoBack: {
      type: Function,
      default: null
    }
  },
  mounted() {
    if (this.small) {
      this.$refs.overlay.style.maxWidth = "1000px";
      this.$refs.overlay.style.height = "fit-content";
      this.$refs.overlay.style.top = "100px";
    }

    this.$refs.contentWrapper.style.maxHeight = window.innerHeight - this.$refs.header.clientHeight - this.$refs.footer.clientHeight + "px";
    this.$refs.contentWrapper.style.marginTop = this.$refs.header.clientHeight + "px";
    this.$refs.contentWrapper.style.marginBottom = this.$refs.footer.clientHeight + "px";

    window.scrollTo(0, 0);
  },
  methods: {
    ...mapMutations({
      close: "overlays/closeOverlay",
      openOverlay: "overlays/openOverlay",
      goBack: "overlays/goBack"
    }),

    closeMethod() {
      if(this.customGoBack) {
        this.customGoBack();
        return;
      }

      if (!this.currentOverlay) this.$router.go(-1);
      else this.close();
    }
  },
  computed: {
    ...mapGetters({
      overlayHistory: "overlays/getOverlayHistory",
      currentOverlay: "overlays/getCurrentOverlay"
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/global";

.overlay {
  z-index: 999;

  position: absolute;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 100%;
  //max-width: 1000px;
  top: 0;
  left: 50%;
  border-radius: 10px;
  background: #ffffff;
  overflow: hidden;
}

.bi-x-lg {
  color: $gray-400;
  cursor: pointer;

  &:hover {
    color: $gray-700 !important;
  }
}

.header, .footer {
  background: $gray-100;
  position: absolute;

  &.footer {
    bottom: 0;
    width: 100%;
  }

  &.header {
    top: 0;
    width: 100%;
    z-index: 999;
  }
}

.header, .footer, .content {
  padding: 1rem;
}
</style>