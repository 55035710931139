import db from "@/store/helpers/crud";

export default {
    namespaced: true,

    state() {
        return {
            ...db.state,

            importMaps: [],
            importMap: null
        }
    },

    mutations: {
        ...db.mutations,

        setImportMap(state, payload) {
            state.importMap = payload;
        },
        setImportMaps(state, payload) {
            state.importMaps = payload;
        },
        pushImportMap(state, payload) {
            state.importMaps.push(payload);
        },
        deleteImportMap(state, id) {
            const index = state.importMaps.indexOf(state.importMaps.find(map => map.id === id));

            if (index === -1)
                throw new Error(`Import map ${id} doesn't exist.`);

            state.importMaps.splice(index, 1);
        },
        updateImportMap(state, {id, payload}) {
            if (!id)
                Object.assign(state.importMaps, payload);
            else {
                const importMap = state.importMaps.find(c => c.id === id);

                if (!importMap)
                    throw new Error(`Import map ${id} doesn't exist.`);

                Object.assign(state.importMaps[state.importMaps.indexOf(importMap)], payload);
            }
        }
    },

    actions: {
        async fetchImportMaps({commit, state}) {
            const [importMaps, count, lastSnapshot] = await db.list({collectionName: "importMaps"});

            commit("setImportMaps", importMaps);

            if (!state.initialized)
                commit("initialize");
        },
        async deleteImportMap(context, id) {
            if (context.getters.getImportMap?.id === id)
                context.commit("setImportMap", null);

            context.commit("deleteImportMap", id);

            await db.delete("importMaps", id);
        },
        async updateImportMap(context, {id, payload}) {
            let importMap = context.getters.getImportMapById(id);

            context.commit("updateImportMap", {id, payload});

            try {
                await db.update("importMaps", importMap.id, payload);
            } catch (e) {
                // context.commit("setImportMap", importMap);
                context.commit("updateImportMap", {id, payload: importMap});

                return Promise.reject(e);
            }
        },
        async createNewImportMap(context, payload) {
            try {
                payload.id = await (db.create("importMaps", payload)).id;

                context.commit("pushImportMap", payload);
                context.commit("setImportMap", payload);
            } catch (e) {
                return Promise.reject(e);
            }
        },
    },

    getters: {
        ...db.getters,

        getImportMaps(state) {
            return state.importMaps;
        },
        getImportMapById: (state) => (id) => state.importMaps.find(c => c.id === id),
        getImportMap(state) {
            return state.importMap;
        }
    }
}