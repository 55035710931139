import firebaseApp from "@/firebaseApp";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    connectAuthEmulator
} from "firebase/auth";
import db from "../helpers/crud";
import cloudFunctionsController from "@/mixins/api_controllers/cloudFunctionsController";

const auth = getAuth(firebaseApp);
// connectAuthEmulator(auth, 'http://localhost:9099');

export default {
    namespaced: true,

    state() {
        return {
            user: null,
            roleInCurrentSubject: null,
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        updateUser(state, payload) {
            state.user = {...state.user, ...payload};
        },
        setRoleInCurrentSubject(state, payload) {
            state.roleInCurrentSubject = payload;
        }
    },
    actions: {
        async initialize({commit, dispatch}) {
            const user = await new Promise((resolve, reject) => {
                const unsubscribe = auth.onAuthStateChanged(async (user) => {
                    if (user) {
                        unsubscribe();

                        const token = await user.getIdToken(); // Získa ID token
                        resolve({...user, token});
                    } else
                        reject("user_not_logged");
                });
            });

            commit("setUser", user);
            const userData = await db.retrieve("users", user.uid, true);
            commit("setUser", {...user, ...userData});

            const roleData = await db.retrieve("permissions", user.uid);
            commit("setRoleInCurrentSubject", roleData.role);

            if (roleData.role === "client")
                await dispatch("clients/fetchClient", userData.relatedClientId, {root: true});
        },

        async create({commit}, {email, password}) {
            let user = (await createUserWithEmailAndPassword(auth, email, password)).user;

            const subjectId = (await db.create("subjects", {
                isOrganization: false,
            }, true)).id;

            const payload = {
                smtpEmail: user.email,
                activeSubject: subjectId,
                subjects: [subjectId]
            }

            await db.create("users", payload, true, user.uid);

            user = {...user, ...payload};

            commit("setUser", user)
        },
        async login(context, {email, password}) {
            const user = (await signInWithEmailAndPassword(auth, email, password)).user;

            context.commit("setUser", user)
        },
        async logout() {
            await signOut(auth);

            //Kôli resetu storu
            location.reload();
        },
        async updateUser({getters, commit}, payload) {
            const userClone = JSON.parse(JSON.stringify(getters.getUser));

            commit("updateUser", payload);

            try {
                await db.update("users", userClone.id, payload, true);
            } catch (e) {
                commit("setUser", userClone);

                throw e;
            }
        },

        async removeUser({commit}, {uid, subjectId, clientId}) {
            cloudFunctionsController.methods.initAxios()
            await cloudFunctionsController.methods.removeUser({uid, subjectId, clientId});


            //TODO: odstrániť všetko na FE podľa BE bez reinicializácie storu
        }
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getRoleInCurrentSubject(state) {
            return state.roleInCurrentSubject;
        }
    }
}