import firebaseApp from "@/firebaseApp";
import {getStorage, uploadBytes, ref, deleteObject, getDownloadURL, listAll, getMetadata} from "firebase/storage";

import cloudFunctionsController from "@/mixins/api_controllers/cloudFunctionsController";

//Vytváram falošný kontext, kt. má dáta potrebné pre použitie metód z cloudFunctionsController.js
const cfEnv = {
    axiosCF: cloudFunctionsController.methods.initAxios(true),
}

export const storageObject = getStorage(firebaseApp);

export default {
    state() {
        return {
            rootLocation: null,
        }
    },
    mutations: {
        setRootLocation(state, payload) {
            state.rootLocation = payload;
        }
    },
    actions: {
        initRootLocation({commit, dispatch, rootGetters}) {
            const rootLocation = `client-storage/${rootGetters['user/getUser'].activeSubject}/`;
            commit("setRootLocation", rootLocation);
        }
    },
    getters: {
        getRootLocation(state) {
            return state.rootLocation;
        }
    },

    async upload(location, file) {
        const locationWithoutFilename = location.slice(0, location.lastIndexOf("/"));
        const fileName = location.slice(location.lastIndexOf("/") + 1);
        //Ak je v danom folderi už súbor s rovnakým názvom, pridáme za názov číslo v tvare "_1", "_2" atď.
        //call() používam kôli kontextu vyššie
        const safeFileName = (await cloudFunctionsController.methods.generateSafeFileName.call(cfEnv, locationWithoutFilename, fileName)).safeFileName;

        const storageRef = ref(storageObject, `${locationWithoutFilename}/${safeFileName}`);
        return uploadBytes(storageRef, file);
    },
    download(location) {
        return getDownloadURL(ref(storageObject, location));
    },
    delete(location) {
        const storageRef = ref(storageObject, location);

        return deleteObject(storageRef);
    },
    async deleteFolder(location) {
        const storageRef = ref(storageObject, location);

        listAll(storageRef).then((result) => {
            return Promise.all(result.items.map(async (itemRef) => {
                return await deleteObject(itemRef).catch((error) => {
                    console.error(`Error deleting file ${itemRef.fullPath}:`, error);
                    throw new Error;
                });
            }));
        });

    },
    async overwrite(location, file) {
        await this.delete(location);
        return this.upload(location, file);
    },
    list(location) {
        const storageRef = ref(storageObject, location);

        return listAll(storageRef).then(async res => {
            const items = await Promise.all(res.items.map(async (itemRef) => await getMetadata(itemRef)));

            return {
                prefixes: res.prefixes,
                items,
            }
        });
    },
    createFolder(location) {
        return this.upload(`${location}.IS_FOLDER`, new Blob([]));
    }
}