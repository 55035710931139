import _axios from 'axios';
import fileHelper from "@/mixins/fileHelper";

//TODO: Upratať podľa BE modulov

export default {
    data() {
        return {
            axiosCF: null,
        }
    },
    mixins: [fileHelper],
    created() {
        this.initAxios();
    },
    methods: {
        initAxios(returnInstance = false) {
            const PRODUCTION_URL = 'https://europe-west1-data-gather-605a4.cloudfunctions.net';
            const EMULATOR_URL = 'http://127.0.0.1:5001/data-gather-605a4/europe-west1'

            const axiosConfig = {
                baseURL: PRODUCTION_URL,
            };

            if (returnInstance)
                return _axios.create(axiosConfig);

            this.axiosCF = _axios.create(axiosConfig);
        },

        async convertToPdf(b64, name, config = {}) {
            const pdfB64 = (await this.axiosCF.post('convertToPdf', {
                b64,
            }, config)).data;

            return await this.b64ToFile(pdfB64, name, 'application/pdf');
        },
        async encryptPdf(b64, password, name, config = {}) {
            return (await this.axiosCF.post('encryptPdf', {
                b64,
                password,
            }, config)).data;
        },

        async autocomplete(query) {
            if (query.length < 3) throw new Error("Dĺžka query parametru mmusí byť aspoň 3!");

            return (await this.axiosCF.post(`autocomplete/?query=${query}`)).data;
        },

        async companyDetail(ico) {
            return (await this.axiosCF.post(`companyDetail/?ico=${ico}`)).data;
        },

        async htmlToPdf(html) {
            return (await this.axiosCF.post('htmlToPdf', {
                content: html
            }, {
                responseType: "blob",
                headers: {
                    'Content-Type': 'application/json'
                }
            })).data;
        },

        async inviteUser(userEmail, subjectId) {
            return (await this.axiosCF.post("generateInvitationToken/", {
                userEmail,
                subjectId,
            })).data;
        },
        async finalizeInvitation(payload) {
            return (await this.axiosCF.post("finalizeInvitation/", payload)).data;
        },
        async generatePasswordForClient(userId) {
            return (await this.axiosCF.post("generatePasswordForClient/", {
                userId,
            })).data;
        },

        async removeUser(payload) {
            return (await this.axiosCF.post("removeUser/", payload)).data;
        },

        async generateSafeFileName(location, fileName) {
            return (await this.axiosCF.post("generateSafeFileName/", {
                location,
                fileName,
            })).data;
        }
    }
}