import db from "@/store/helpers/crud";

//TODO: Vytvoriť nested moduly pre každý model

export default {
    state: {
        models: {}
    },

    namespaced: true,

    mutations: {
        ...db.mutations,

        setModels(state, payload) {
            state.models = payload;
        },
        setModel(state, {model, payload}) {
            state.models[model] = payload;
        },

        addChoiceToModel(state, {model, option, field, bulk}) {
            state.models[model] = state.models[model] || {};
            state.models[model].choices = state.models[model].choices || {};

            if (state.models[model].choices[field])
                bulk ? state.models[model].choices[field].push(...option)
                    : state.models[model].choices[field].push(option);
            else
                state.models[model].choices[field] = bulk ? option : [option];
        },
        deleteChoice(state, {forModel, field, optionValue}) {
          state.models[forModel].choices[field] = state.models[forModel].choices[field].filter(o => o.value !== optionValue);
        },
        updateChoiceVerbose(state, {forModel, field, optionValue, optionVerbose}) {
            const index = state.models[forModel].choices[field].findIndex(o => o.value === optionValue);

            state.models[forModel].choices[field][index] = {value: optionValue, verbose: optionVerbose};
        },

        addFieldToModel(state, {model, field, type, verbose, taggable}) {
            state.models[model] = state.models[model] || {};
            state.models[model].fields = state.models[model].fields || {};

            state.models[model].fields[field] = {
                type,
                verbose,
                taggable
            };
        },
        deleteField(state, {forModel, field}) {
            const fieldCopy = JSON.parse(JSON.stringify(state.models[forModel].fields[field]));

            delete state.models[forModel].fields[field];

            if(fieldCopy.taggable)
                delete state.models[forModel].choices[field];
        },
        updateFieldVerbose(state, {forModel, field, verbose}) {
            state.models[forModel].fields[field].verbose = verbose;
        },
    },

    actions: {
        ...db.actions,

        async initialize({commit}) {
            const [data] = await db.list({
                collectionName: "schema",
                pageSize: 0,
                _orderBy: null,
            });

            const models = data.reduce((base, obj) => {
                const {id, ...rest} = obj;

                return {
                    [id]: {
                        ...rest
                    }
                }
            }, {});

            commit('setModels', models);
            commit('initialize');
        },

        async createChoice({commit, getters}, {option, forModel, field, bulk, onlyPayload}) {
            if (!forModel) throw new Error("No model specified");

            bulk = bulk || false;
            onlyPayload = onlyPayload || false;

            const model = getters.getModel(forModel);

            commit("addChoiceToModel", {model: forModel, option, field, bulk});

            //Payload sa mení v mutácií, tu si iba retrievneme aktuálny stav
            const options = model.choices[field];

            //V prípade, že updatujeme choices priamo pri vytváraní fieldu
            //Vtedy túto akciu voláme iba pre zostrojenie payloadu a firenutie mutacii
            if (onlyPayload)
                return {
                    ...model.choices,

                    [field]: options
                }

            await db.update("schema", forModel, {
                choices: {
                    ...model.choices,

                    [field]: options
                }
            });
        },
        async deleteChoice({commit, getters}, {forModel, field, optionValue}) {
            commit("deleteChoice", {forModel, field, optionValue});

            const choices = getters.getChoicesForModel(forModel);

            await db.update("schema", forModel, {
                choices,
            });
        },
        async updateChoiceVerbose({commit, getters}, {forModel, field, optionVerbose, optionValue}) {
            commit("updateChoiceVerbose", {forModel, field, optionVerbose, optionValue});

            const choices = getters.getChoicesForModel(forModel);

            await db.update("schema", forModel, {
                choices,
            });
        },

        async createField({commit, getters, dispatch}, {forModel, field, type, verbose, choices, taggable}) {
            if (!forModel) throw new Error("No model specified");

            taggable = taggable || false;

            //Kontrola štruktúry
            if (!field ||
                !type ||
                !verbose
            ) throw new Error("Invalid arguments");

            commit("addFieldToModel", {
                model: forModel,
                field,
                type,
                verbose,
                taggable,
            });

            const model = getters.getModel(forModel);

            const fields = model?.fields || {};

            const payload = {
                fields: {
                    ...fields,
                    [field]: {
                        type,
                        verbose,
                        taggable
                    }
                }
            }

            //Ak potrebujeme vytvárať aj choices
            if (taggable && choices?.length)
                payload.choices = await dispatch("createChoice", {
                    forModel,
                    field,
                    option: choices,
                    bulk: true,
                    onlyPayload: true
                });

            await db.update("schema", forModel, payload);
        },
        async deleteField({getters, commit}, {field, forModel}) {
            commit("deleteField", {field, forModel});

            await db.update("schema", forModel, {
                fields: getters.getFieldsForModel(forModel),
                choices: getters.getChoicesForModel(forModel)
            });
        },
        async updateFieldVerbose({getters, commit}, {forModel, field, verbose}) {
            commit("updateFieldVerbose", {forModel, field, verbose});

            await db.update("schema", forModel, {
                fields: getters.getFieldsForModel(forModel),
                choices: getters.getChoicesForModel(forModel)
            });
        }

        // async fetchChoices({ commit }, payload) {
        //     commit('setChoices', payload);
        // }
    },

    getters: {
        ...db.getters,

        getChoicesForModel: (state) => (model) => state.models[model]?.choices,
        getFieldsForModel: (state) => (model) => state.models[model]?.fields,
        getModel: (state) => (model) => state.models[model],
    }
}