import PizZip from "pizzip";
import _ from "lodash";

import ImageModule from "docxtemplater-image-module-free";
import Docxtemplater from "docxtemplater";

import fileHelper from "@/mixins/fileHelper";

export default {
    mixins: [fileHelper],
    methods: {
        async initDocxtemplater(documentDataArrayBuffer, noInspectModule = false) {
            const doc = new Docxtemplater();

            const zip = new PizZip(documentDataArrayBuffer);

            doc.loadZip(zip);

            const moduleInstances = this.attachModules(doc, noInspectModule);

            try {
                doc.compile()
            } catch (error) {
                const e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties,
                }

                console.log(JSON.stringify({error: e}));

                // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
                throw error;
            }

            return {doc, moduleInstances};
        },
        attachModules(docxtemplaterInstance, noInspectModule) {
            const imageModule = new ImageModule({
                getImage: function (value) {
                    const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;

                    if (!base64Regex.test(value))
                        return false;

                    const stringBase64 = value.replace(base64Regex, "");
                    let binaryString;

                    if (typeof window !== "undefined")
                        binaryString = window.atob(stringBase64);
                    else
                        binaryString = new Buffer(stringBase64, "base64").toString("binary");

                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);

                    for (let i = 0; i < len; i++)
                        bytes[i] = binaryString.charCodeAt(i);

                    return bytes.buffer;
                },
                getSize: function (img, tag, value) {
                    return [200, 75];
                }
            })

            docxtemplaterInstance.attachModule(imageModule);

            if (noInspectModule) return {imageModule};

            class InspectModule {
                constructor() {
                    this.inspect = {};
                }

                set(obj) {
                    if (obj.inspect) {
                        this.inspect = _.merge({}, this.inspect, obj.inspect);
                    }
                }
            }

            const inspectModule = new InspectModule();
            docxtemplaterInstance.attachModule(inspectModule);


            return {inspectModule, imageModule};
        },

        initTags(inspectModule) {
            const getTags = (postParsed) => postParsed
                .filter((part) => part.type === "placeholder")
                .reduce((tags, part) => {
                    let key = part.value;

                    if (key === 'meta') throw new Error('Tag meta is reserved for internal use!');

                    let foreignModel = null;
                    if (key[0] === "*") {
                        const sliced = key.slice(1, key.length);

                        key = sliced.split("__")[1];
                        foreignModel = sliced.split("__")[0];
                    }

                    if (!tags[part.value]) {
                        tags[key] = {};

                        if (part.raw?.includes("^") || part.raw?.includes("#"))
                            tags[key].type = "boolean";
                        else if (part.raw?.includes("%"))
                            tags[key].type = "img";

                        if (foreignModel)
                            tags[key].foreignModel = foreignModel;
                    }

                    if (part.subparsed) {
                        tags[key] = {...tags[key], ...getTags(part.subparsed)};

                        if (Object.keys(tags[key]).filter((key) => key !== "type").length)
                            tags[part.value].type = "loop";
                    }

                    return tags;
                }, {});

            const postParsed = inspectModule.inspect.postparsed;
            return getTags(postParsed);
        },
        buildDocData(tags, model) {
            return Object.keys(tags).reduce((obj, key) => {
                //field meta je použitý na storovanie idčok klienta...
                if (key === 'meta') {
                    //Tu musí byt deep copy, lebo inak sa všetky meta zmenia na poslednú meta hodnotu
                    obj[key] = JSON.parse(JSON.stringify(tags[key]));
                    return obj;
                }

                obj[key] = {};
                obj[key].value = model[key] || null;
                obj[key].type = tags[key].type || "text";

                if (obj[key].type === "loop")
                    obj[key].schema = Object.keys(tags[key]).filter((key) => key !== "type");

                return obj;
            }, {});
        },

        async addContentAndConvertToPdf(docxtemplaterInstance, processedData, document) {
            if (document.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                throw new Error('Unsupported document type');

            const data = Object.entries(processedData).reduce((obj, [key, value]) => {
                obj[key] = value.value;

                if (!value.value && value.value !== 0)
                    obj[key] = '';

                return obj;
            }, {});

            docxtemplaterInstance.setData(data);
            docxtemplaterInstance.render();

            // Debug-start POZOR: pri debugu(stahovani docx) appka padne
            // const out = docxtemplaterInstance.getZip().generate({
            //   type: "blob",
            //   mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            // });
            //
            // const url = URL.createObjectURL(out);
            //
            // const link = document.createElement('a');
            // link.href = url;
            // link.download = 'myDocument.docx';  // Meno súboru, ktorý chcete pri stiahnutí
            //
            // // Pridanie linku do DOMu, kliknutie naň a následné odstránenie
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            // Debug-end

            // Prod-start
            const b64 = docxtemplaterInstance.getZip().generate({
                type: "base64",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });

            return await this.fileToUint8Array(await this.docxToPdf(b64, document.name));
            // Prod-end
        },
    }
}