import {createStore} from 'vuex'

import user from "@/store/modules/user";
import clients from "@/store/modules/clients";
import documents from "@/store/modules/documents";
import _import from "@/store/modules/import";
import cloud from "@/store/modules/cloud";
import overlays from "@/store/modules/overlays";
import invoices from "@/store/modules/invoices";
import subjectData from "@/store/modules/subjectData";
import permissions from "@/store/modules/permissions";
import schema from "@/store/modules/schema";

export default createStore({
    state() {
        return {
            storeInitialized: false,
        }
    },
    mutations: {
        initializeStore(state) {
            state.storeInitialized = true;
        },
    },
    actions: {
        async initStore(context) {
            await context.dispatch("user/initialize");

            const promises = [];

            promises.push(
                context.dispatch("cloud/initRootLocation"),
                context.dispatch("subjectData/initialize"),
                context.dispatch("schema/initialize"),
                // context.dispatch("permissions/initialize"),
            );

            await Promise.all(promises);

            context.commit("initializeStore");
        },
    },
    getters: {
    },
    modules: {
        user,
        clients,
        documents,
        _import,
        cloud,
        overlays,
        invoices,
        subjectData,
        permissions,
        schema,
    }
})
