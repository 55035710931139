import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const routes = [
    {
        path: "/",
        redirect: {name: "Idocs"}
    },
    {
        path: "/public/",
        name: "Public",
        component: () => import("../views/Public.vue"),
        children: [
            {
                path: "idocs",
                name: "Idocs",
                component: () => import("../containers/public/Idocs.vue"),
            }
        ]
    },
    {
        path: "/auth/",
        component: () => import("../views/AuthView.vue"),
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import("../containers/auth/Login.vue")
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import("../containers/auth/Register.vue"),
            },
        ]
    },
    {
        path: "/app/",
        name: "AppView",
        component: () => import("../views/AppView.vue"),

        meta: {
            requiresLogin: true
        },

        children: [
            // {
            //     path: "dashboard",
            //     name: "Dashboard",
            //     component: () => import("../containers/app/Dashboard.vue"),
            // },

            {
                path: "klienti",
                name: "Clients",
                component: () => import("../containers/app/Clients.vue"),
            },
            {
                path: "klienti/import",
                name: "ImportClients",
                component: () => import("../containers/app/overlays/ImportClientsOverlay.vue"),
            },
            {
                path: "klienti/:id",
                name: "ClientDetail",
                component: () => import("../containers/app/ClientDetail.vue"),
            },
            {
                path: "klienti/:clientId/dokument/:docId",
                name: "DocumentViewer",
                component: () => import("../containers/app/overlays/DocumentViewerOverlay.vue"),
            },
            {
                path: "klienti/novy",
                name: "NewClient",
                component: () => import("../containers/app/overlays/NewClientOverlay.vue"),
            },

            {
                path: "predlohy",
                name: "Documents",
                component: () => import("../containers/app/Documents.vue"),
            },
            {
                path: "mapovanie-dokumentu/:id",
                name: "DocumentMapper",
                component: () => import("../containers/app/overlays/DocumentMapperOverlay.vue"),
            },

            {
                path: "schema/:model",
                name: "SchemaBuilder",
                component: () => import("../containers/app/overlays/SchemaBuilderOverlay.vue"),
            },

            {
                path: "cloud-ulozisko",
                name: "CloudStorage",
                component: () => import("../containers/app/CloudStorage.vue")
            },

            {
                path: "moja-organizacia",
                name: "MyOrganization",
                component: () => import("../containers/app/MyOrganization.vue")
            },

            // {
            //     path: "fakturacia",
            //     name: "Invoices",
            //     component: () => import("../containers/app/Invoices.vue")
            // },
            // {
            //     path: "fakturacia/nova",
            //     name: "NewInvoice",
            //     component: () => import("../containers/app/overlays/NewInvoiceOverlay.vue")
            // },
            // {
            //     path: "fakturacia/:id",
            //     name: "InvoiceDetail",
            //     component: () => import("../containers/app/InvoiceDetail.vue")
            // },

            {
                path: "nastavenia",
                name: "Settings",
                component: () => import("../containers/app/Settings.vue")
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    try {
        if (!store.state.storeInitialized)
            await store.dispatch("initStore");
    } catch (e) {
        console.error(e);
    }

    if (to.meta?.requiresLogin && !store.getters["user/getUser"])
        return next({name: "Login"});

    return next();
});

export default router
