<template>
  <div :class="{big}">
    <div><b>{{ autocompleteCompanyDetail.Name }}</b> {{ autocompleteCompanyDetail.LegalFormText }}</div>
    <div>{{ autocompleteCompanyDetail.Street }}, {{ autocompleteCompanyDetail.StreetNumber }}</div>
    <div>{{ autocompleteCompanyDetail.City }}</div>
    <div>{{ autocompleteCompanyDetail.ZipCode }}</div>

    <div class="d-flex gap-default flex-wrap">
      <div><b>IČO:</b> {{ autocompleteCompanyDetail.Ico }}</div>
      <div v-if="autocompleteCompanyDetail.Dic"><b>DIČ:</b> {{ autocompleteCompanyDetail.Dic }}</div>
      <div v-if="autocompleteCompanyDetail.IcDPH"><b>IČ DPH:</b> {{ autocompleteCompanyDetail.IcDPH }}</div>
    </div>

    <div class="d-flex gap-default flex-wrap" v-if="autocompleteCompanyDetail.bankAccount">
      <div><b>IBAN</b> {{ autocompleteCompanyDetail.bankAccount.IBAN }}</div>
      <div><b>SWIFT</b> {{ autocompleteCompanyDetail.bankAccount.SWIFT }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyDetail",
  props: {
    autocompleteCompanyDetail: {
      type: Object,
      required: true,
    },
    big: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.big {
  font-size: 1.3rem;
}
</style>