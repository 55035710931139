export default {
    data() {
        return {
            wayOfPaymentChoices: [
                { label: 'Hotovosť', value: 'CASH' },
                { label: 'Bankový prevod', value: 'TRANSFER' },
                { label: 'Dobierka', value: 'CASH_ON_DELIVERY' },
                { label: 'Kreditná karta', value: 'CARD' },
            ]
        }
    }
}