export default {
    namespaced: true,
    state() {
        return {
            currentOverlay: null,
            currentOverlayProps: null,
            overlayHistory: []
        }
    },
    mutations: {
        openOverlay(state, {overlayName, props, goingBack}) {
            const getOverlayLabel = (overlayName) => {
                switch (overlayName) {
                    case 'DocumentMapperOverlay':
                        return 'mapovanie dokumentov';
                    case 'DocumentViewerOverlay':
                        return 'prehľad dokumentu';
                    case "NewDocumentOverlay":
                        return 'nový dokument';
                    case "NewInvoiceOverlay":
                        return 'nová faktúra';
                }
            }

            if (state.currentOverlay && !goingBack) {
                state.overlayHistory.push({
                    overlayName: state.currentOverlay,
                    props: state.currentOverlayProps,
                    label: getOverlayLabel(state.currentOverlay)
                });
            }

            state.currentOverlayProps = props;
            state.currentOverlay = overlayName;
        },
        closeOverlay(state) {
            state.currentOverlay = null;
        },
        removeLastHistory(state) {
            state.overlayHistory.pop();
        },
        goBack(state) {
            const lastOverlay = state.overlayHistory.pop();
            state.currentOverlay = lastOverlay.overlayName;
            state.currentOverlayProps = lastOverlay.props;
        }
    },
    getters: {
        getCurrentOverlay(state) {
            return state.currentOverlay;
        },
        getVisible(state) {
            return state.visible;
        },
        getCurrentOverlayProps(state) {
            return state.currentOverlayProps;
        },

        getOverlayHistory(state) {
            return state.overlayHistory;
        },
    }
}