import storage from '../helpers/storage';
import db from "../helpers/crud";


export default {
    namespaced: true,
    state() {
        return {
            ...storage.state(),

            initialized: false,

            absoluteCurrentPath: null,

            currentFile: null,

            itemsInCurrentPath: [],
        }
    },
    mutations: {
        ...storage.mutations,

        setCurrentPath(state, path) {
            state.absoluteCurrentPath = path;
        },
        setCurrentFile(state, file) {
            state.currentFile = file;
        },
        setItemsInCurrentPath(state, items) {
            state.itemsInCurrentPath = items;
        },
        pushItemToCurrentPath(state, item) {
            state.itemsInCurrentPath.push(item);
        },
        initialize(state) {
            state.initialized = true;
        }
    },
    actions: {
        ...storage.actions,

        async initCloudStorage({commit, dispatch, getters}) {
            commit("setCurrentPath", getters.getRootLocation);
            await dispatch("fetchItemsInCurrentPath");

            commit("initialize");
        },
        async fetchItems({commit, dispatch, getters}, path) {
            const items = await storage.list(path);

            const folders = items.prefixes.map(folder => {
                return {
                    name: folder.name,
                    type: "folder",
                    location: folder.fullPath,
                }
            });

            const files = items.items.map(file => {
                return {
                    name: file.name,
                    type: "file",
                    location: file.fullPath,
                    size: file.size,
                    createdAt: file.timeCreated,
                }
            });

            return [...folders, ...files];
        },
        async fetchItemsInCurrentPath({commit, getters, dispatch}) {
            commit("setItemsInCurrentPath", db.addMeta(await dispatch("fetchItems", getters.getAbsoluteCurrentPath)));
        },

        async uploadToCloudStorage({commit, dispatch, getters, rootGetters}, file) {
            const location = getters.getAbsoluteCurrentPath + file.name;

            await storage.upload(location, file);
            await dispatch("fetchItemsInCurrentPath");
        },
        async deleteFromCloudStorage({commit, dispatch, getters, rootGetters}, location) {
            await storage.delete(location);
            await dispatch("fetchItemsInCurrentPath");
        },
        async deleteFolderFromCloudStorage({commit, dispatch, getters, rootGetters}, folderLocation) {

        },

        async createFolder({commit, dispatch, getters, rootGetters}, folderName) {
            const location = getters.getAbsoluteCurrentPath + folderName + "/";

            commit("pushItemToCurrentPath", {
                name: folderName,
                type: "folder",
                location,
            });

            await storage.createFolder(location);
        },

        async openFolder({commit, dispatch, getters, rootGetters}, location) {
            commit("setCurrentPath", location + "/");
            await dispatch("fetchItemsInCurrentPath");
        },

        async goBack({commit, dispatch, getters}) {
            const splitted = getters.getAbsoluteCurrentPath.split("/");
            splitted.splice(-2);
            const path = splitted.join("/") + "/";

            commit("setCurrentPath", path);
            await dispatch("fetchItemsInCurrentPath");
        },

        async downloadFromCloudStorage({commit, dispatch, getters, rootGetters}, location) {
            return await storage.download(location);
        }
    },
    getters: {
        ...storage.getters,

        getAbsoluteCurrentPath(state) {
            return state.absoluteCurrentPath;
        },
        getCurrentPath(state) {
            return state.absoluteCurrentPath.split("/").slice(2).join("/");
        },
        getCurrentFile(state) {
            return state.currentFile;
        },
        getItemsInCurrentPath(state) {
            return state.itemsInCurrentPath;
        },
        getInitialized(state) {
            return state.initialized;
        }
    }
}