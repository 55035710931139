import db from "../helpers/crud";

export default {
    namespaced: true,

    state() {
        return {
            permissions: {},
            initialized: false,
        }
    },

    mutations: {
        setPermissions(state, payload) {
            state.permissions = payload;
        },
        initialize(state) {
            state.initialized = true;
        },
    },

    actions: {
        async initialize({commit}) {
            try {
                const [permissions] = await db.list({
                    collectionName: "permissions",
                    pageSize: 0,
                    _orderBy: "role",
                    order: "asc"
                });

                commit("setPermissions", permissions);
            } catch (e) {
                console.error(e);
            } finally {
                commit("initialize");
            }
        }
    },

    getters: {
        getPermissions(state) {
            return state.permissions;
        },

        getInitialized(state) {
            return state.initialized;
        }
    }
}